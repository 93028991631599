import { Box, Tab, TabList, Tabs, useToast } from '@chakra-ui/react';
import { GetIndependentLearningStateResponse } from '@sparx/api/apis/sparx/science/packages/v1/independentlearning';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Course, CourseTier } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { useQueryState } from 'api/client';
import { useIndependentLearningUserState } from 'api/independentlearning';
import { useCreateIndependentLearningPackage, usePackages } from 'api/packages';
import { useSession, useUserType } from 'api/sessions';
import { useBackLink } from 'app/BackLink';
import { StudentNavigation } from 'app/StudentNavigation';
import { LargeLoadingWithText } from 'components/loading/LargeLoading';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreateATask } from './CreateATask';
import { PreviousTasks } from './PreviousTasks';

export const IndependentLearningView = () => {
  // Show a back button to the teacher interface if the user is a teacher
  const { isTeacher } = useUserType();
  useBackLink(isTeacher ? '/teacher' : undefined);
  return (
    <StudentNavigation>
      <CoreIndependentLearningView />
    </StudentNavigation>
  );
};

export interface IndependentLearningContext {
  createTask: (resourceName?: string, displayName?: string, level?: string) => void;
  userState: GetIndependentLearningStateResponse;
  previousPackages: Package[];
  curriculumName: string;
  setCurriculumName: (curriculumName: string) => void;
  defaultLevel: string;
  level: string;
  setLevel: (level: string) => void;
}

const IndependentLearningContext = React.createContext<IndependentLearningContext>(
  {} as IndependentLearningContext,
);
export const useIndependentLearningContext = () => useContext(IndependentLearningContext);

const CoreIndependentLearningView = () => {
  const tabs = [
    { name: 'Create a task', element: CreateATask },
    { name: 'Previous tasks', element: PreviousTasks },
  ];
  const [currentTab, setCurrentTab] = useState(0);
  const ContentElement = tabs[currentTab].element;

  const generatePackage = useCreateIndependentLearningPackage();
  const [generatingDisplayName, setGenerateDisplayName] = useState('');
  const navigate = useNavigate();
  const { data: userState } = useIndependentLearningUserState({
    suspense: true,
  });

  const [curriculumName, setCurriculumName] = useQueryState(
    `independent-learning-selected-curriculum`,
    userState?.curriculumName || 'curriculums/7bad86c6-9afa-11ed-a0de-7b9493235937',
  );

  const { data: { userLevel: userLevel = '2' } = {} } = useSession({ suspense: true });
  const [level, setLevel] = useQueryState('independent-learning-selected-level', userLevel);

  const { data: allPackages = [] } = usePackages({ suspense: true });
  const previousPackages = useMemo(
    () =>
      allPackages
        .filter(p => p.type === 'independentlearning')
        .sort((a, b) => (b.startTimestamp?.seconds || 0) - (a.startTimestamp?.seconds || 0)),
    [allPackages],
  );
  const toast = useToast();

  const createTask = (resourceName?: string, displayName?: string) => {
    generatePackage.mutate(
      { contentResource: resourceName, level: level },
      {
        onError: () => {
          toast({
            title: 'Error',
            description: 'An error occured, please try again or select a different topic.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left',
          });
          generatePackage.reset();
        },
        onSuccess: data => {
          if ((data.package[0]?.contents?.tasks.length || 0) > 0) {
            const pkg = data.package[0];
            const task = pkg.contents!.tasks[0]!;
            navigate(`/${task.name}`);
          } else {
            generatePackage.reset();
          }
        },
      },
    );
    setGenerateDisplayName(displayName || '');
  };

  // Show spinner while loading, or on success while we wait for the navigate to happen
  if (generatePackage.isLoading || generatePackage.isSuccess) {
    return (
      <LargeLoadingWithText>
        {generatingDisplayName ? (
          <>Building you a task on {generatingDisplayName}...</>
        ) : (
          <>Building your personal practice...</>
        )}
      </LargeLoadingWithText>
    );
  }

  return (
    <IndependentLearningContext.Provider
      value={{
        createTask,
        userState: userState || {
          previousTopics: [],
          course: Course.COMBINED,
          tier: CourseTier.LEVEL_FOUNDATION,
          curriculumName: 'curriculums/7bad86c6-9afa-11ed-a0de-7b9493235937',
        },
        previousPackages,
        curriculumName,
        setCurriculumName,
        defaultLevel: userLevel,
        level,
        setLevel,
      }}
    >
      <PageContainer>
        <Box pt={2}>
          <PageTitle title="Independent Learning" />
        </Box>
        <Tabs
          index={currentTab}
          onChange={setCurrentTab}
          colorScheme="buttonTeal"
          size={['md', 'lg']}
          variant="line"
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                value={index}
                _selected={{
                  fontWeight: 'bold',
                  color: 'buttonTeal.600',
                  borderBottomWidth: 2,
                  borderBottomColor: 'buttonTeal.600',
                }}
              >
                {tab.name}
              </Tab>
            ))}
          </TabList>
          <Box width="100%" borderWidth={1} mt="-1.5px" />
        </Tabs>
        <Box py={4}>
          <ContentElement />
        </Box>
      </PageContainer>
    </IndependentLearningContext.Provider>
  );
};
