import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  Activity,
  ActivityActionRequest,
  ActivityActionResponse,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { CreateIndependentLearningPackageRequest } from '@sparx/api/apis/sparx/science/packages/v1/independentlearning';
import {
  GetPackageResponse,
  ListPackagesRequest,
  Package,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { isSparxOverriddenCorrect } from '@sparx/packageactivity';
import {
  answersToAnswerComponents,
  buildStepAnswer,
  countNonMediaEntries,
  getAnswerFiles,
  IInput,
  inputEntryCounts,
} from '@sparx/question';
import { FetchQueryOptions, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import {
  activitiesClient,
  independentLearningClient,
  insightsClient,
  lessonsClient,
  packagesClient,
} from 'api';
import { queryClient } from 'api/client';
import { useCurrentLesson } from 'api/lessons';
import { Options } from 'api/school';
import { getSchoolID } from 'api/sessions';
import { uploadFile } from 'app/storage';
import { useXpContext } from 'components/xp/XpManager/context';

import { fetchInterimAwareYear } from './schoolcalendar';
import { BasicQueryOptions } from './utils';

export const usePackages = (options?: BasicQueryOptions) => {
  const { data: currentLesson } = useCurrentLesson();
  return useQuery({
    queryKey: ['packages'],
    queryFn: async () =>
      packagesClient
        .listPackages(
          ListPackagesRequest.create({
            lessonName: currentLesson?.lessonName || '',
          }),
        )
        .response.then(resp => resp.packages),
    ...options,
  });
};

export const useDemoPackage = () =>
  useMutation({
    mutationFn: async (packageType?: string) =>
      packagesClient.createDemoPackage({
        name: packageType || 'demo', // default to demo
      }).response,
    onSuccess: data => {
      data.package.forEach(updatePackage);
    },
  });

export const clearPackages = () => queryClient.removeQueries(['packages']);

export const useLessonPackage = (lessonName: string, lessonActivityId: string, opts: Options) => {
  const { data: packages } = usePackages(opts);
  const hasPackage = packages?.find(p => p.assignment?.lessonActivityId === lessonActivityId);

  const { mutateAsync } = useMutation({
    mutationFn: async () =>
      lessonsClient.generateLessonActivityPackage({
        lessonName: lessonName,
        lessonActivityId: lessonActivityId,
      }).response,
    onSuccess: resp => {
      if (resp.package) {
        updatePackage(resp.package);
      }
    },
  });

  const update = useMutation({
    mutationFn: async () => {
      if (hasPackage) return hasPackage;
      const resp = await mutateAsync();
      return resp.package;
    },
  });

  return {
    ...update,
    pkg: hasPackage,
  };
};

export const getPackageQuery = (packageId: string): FetchQueryOptions<GetPackageResponse> => ({
  queryKey: ['packages', packageId],
  queryFn: async () => packagesClient.getPackage({ packageId }).response,
});

export const getPackageFromQueryClient = (packageId: string) =>
  queryClient.getQueryData(['packages', packageId]) as GetPackageResponse | undefined;

export const usePackage = (packageId: string, options?: BasicQueryOptions) =>
  useQuery({
    ...getPackageQuery(packageId),
    enabled: !!packageId,
    refetchOnWindowFocus: false,
    ...options,
  });

export const updatePackage = (pkg: Package) => {
  const packageID = pkg.name.split(/\//g)[1];
  queryClient.setQueryData(['packages'], (data: Package[] | undefined) => {
    if (!data) return data;
    return [...data.filter(p => p.name !== pkg?.name), pkg];
  });
  // Only update the single package if it has contents
  if (pkg.contents) {
    queryClient.setQueryData(['packages', packageID], { package: pkg });
  }
};

export const packageSummariesKey = (assignmentID: string) => [
  'assignment',
  assignmentID,
  'summaries',
];

const packageSummariesQuery = (assignmentID: string, options: Options) => ({
  queryKey: packageSummariesKey(assignmentID),
  queryFn: async () =>
    packagesClient.listPackageSummaries({
      schoolName: `schools/${await getSchoolID()}`,
      query: {
        oneofKind: 'assignmentName',
        assignmentName: `assignments/${assignmentID}`,
      },
    }).response,
  ...options,
});

export const usePackageSummaries = (assignmentID: string, options: Options) =>
  useQuery({
    ...packageSummariesQuery(assignmentID, options),
  });

export const useBatchPackageSummaries = (assignmentIDs: string[], options: Options) =>
  useQueries({
    queries: assignmentIDs.map(assignmentID => packageSummariesQuery(assignmentID, options)),
  });

export const useStudentPackageSummaries = (studentID: string, options: Options) =>
  useQuery(
    ['student', studentID, 'summaries'],
    async () => {
      const year = await fetchInterimAwareYear();
      if (!year) return [];

      const { response } = await packagesClient.listPackageSummaries({
        schoolName: `schools/${await getSchoolID()}`,
        query: {
          oneofKind: 'student',
          student: {
            studentName: `students/${studentID}`,
            dates: {
              endTimestamp: Timestamp.fromDate(year.end),
              startTimestamp: Timestamp.fromDate(year.start),
            },
          },
        },
      });
      return response.packageSummaries;
    },
    options,
  );

export const useAssignmentInsights = (assignmentID: string, subject: string, options: Options) =>
  useQuery({
    queryKey: ['assignment', assignmentID, 'insights', subject],
    queryFn: async () =>
      insightsClient.listFocusSkills({
        schoolName: `schools/${await getSchoolID()}`,
        assignmentName: `assignments/${assignmentID}`,
        subject,
      }).response,
    ...options,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 15,
  });

// ACTIVITY ACTIONS

export const useActivity = (taskItemName: string) =>
  useQuery({
    queryKey: ['activity', taskItemName],
    queryFn: async () =>
      activitiesClient
        .getActivity({
          name: '',
          taskItemName,
        })
        .response.then(r => r.activity!),
    onSuccess: act => {
      if (isSparxOverriddenCorrect(act.annotations)) {
        // If this activity was marked correct by sparx (due to some issue),
        // then reload the package to ensure we are showing the correct state
        reloadPackage(taskItemName.split('/')[1]);
      }
    },
    enabled: Boolean(taskItemName),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: 0, // instantly remove when not needed
  });

export const updateActivityState = (activity: Activity) =>
  queryClient.setQueryData(['activity', activity.taskItemName], activity);

export const reloadActivity = (taskItemName: string) =>
  queryClient.invalidateQueries(['activity', taskItemName]);

export const reloadPackage = (packageID: string) =>
  queryClient.invalidateQueries(['packages', packageID]);

interface ActivityAction {
  action: ActivityActionRequest['action'];
  skipActivityUpdate?: boolean;
}

/**
 * useActivityAction is a mutation function that will update the activity state
 * based on a user action.
 *
 * A token is sent with the activity. If this token does not match the one on
 * the server then will return an 'invalid token' error and the activity should
 * be reloaded.
 *
 * The mutation will try to prevent multiple activity actions from being dispatched
 * at the same time. If mutate is called while another is in progress then
 * it will be a noop. The mutateAsync function will return undefined if an action
 * is already in progress.
 */
export const useActivityAction = (activity: Activity) => {
  const { mutate, mutateAsync, isLoading } = useMutation({
    mutationKey: ['activity', activity.name, 'action'],
    mutationFn: async (action: ActivityAction) =>
      activitiesClient.activityAction({
        name: activity.name,
        action: action.action,
        token: activity.state?.token || '',
      }).response,
    onSuccess: (data, req) => {
      data.activity && !req.skipActivityUpdate && updateActivityState(data.activity);
      data.packageUpdate && updatePackage(data.packageUpdate);
    },
    onError: async err => {
      if (err instanceof RpcError && err.code === 'FAILED_PRECONDITION') {
        await Promise.all([
          reloadActivity(activity.taskItemName),
          reloadPackage(activity.taskItemName.split('/')[1]),
        ]);
      }
    },
  });

  return {
    mutate: isLoading
      ? () => {
          /* noop */
        }
      : mutate,
    mutateAsync: isLoading ? undefined : mutateAsync,
    isLoading,
  };
};

/**
 * useActivityNotify is like useActivityAction but doesn't expect an updated activity
 * in the response. This should mainly be used for 'notify' events that are just telling
 * the server of an action rather than actions that expect an updated activity as a
 * response.
 *
 * It will update the package if the response contains a package update.
 *
 * The request will take a blank token so the token on the activity is not updated.
 */
export const useActivityNotify = () =>
  useMutation({
    mutationFn: async ({
      activityName,
      action,
    }: {
      activityName: string;
      action: ActivityAction['action'];
    }) =>
      activitiesClient.activityAction({
        name: activityName,
        action,
        token: '', // No token is provided here - we don't want to regenerate it
      }).response,
    onSuccess: data => {
      data.packageUpdate && updatePackage(data.packageUpdate);
    },
  });

export const useSubmitAnswer = (
  mutateAsync: ((action: ActivityAction) => Promise<ActivityActionResponse>) | undefined,
) => {
  const { addNewAwards } = useXpContext();
  return useMutation({
    mutationFn: async (input: IInput) => {
      if (!mutateAsync) return;

      const answer = buildStepAnswer(input);

      // If the only input for this question are media fields then we can auto
      // progress this step on submit.
      const entryCounts = inputEntryCounts(input);
      const nonInputEntries = countNonMediaEntries(entryCounts);
      const autoProgressStep = nonInputEntries === 0 && entryCounts.media_fields > 0;

      // Upload the file assets
      for (const [key, file] of getAnswerFiles(input)) {
        if (file.file) {
          console.log('uploading file', file.file);
          const uploadedAsset = await uploadFile(file.file);
          answer[key] = { file: uploadedAsset };
          console.log('uploaded file', uploadedAsset);
        }
      }

      const components = answersToAnswerComponents(answer);
      return mutateAsync({
        action: {
          oneofKind: 'answer',
          answer: {
            components,
            autoProgressStep,
          },
        },
      });
    },
    onSuccess: data => {
      const xpAwards = data?.xpAwards;
      if (xpAwards) {
        // store the xp awards in the rewards context
        addNewAwards(xpAwards);
      }
    },
  });
};

export const useSubmitNext = (act: (action: ActivityAction) => void) => (typ: 'next' | 'retry') =>
  act({
    action: typ === 'next' ? { oneofKind: 'next', next: {} } : { oneofKind: 'retry', retry: {} },
  });

export const usePackageAnswerHistory = (packageName: string, userID: string, opts: Options) =>
  useQuery({
    queryKey: ['answerhistory', packageName],
    queryFn: async () =>
      activitiesClient.getPackageAnswerHistory({
        packageName: packageName,
        studentName: 'students/' + userID,
        schoolName: `schools/${await getSchoolID()}`,
      }).response,
    ...opts,
  });

export const useCreateIndependentLearningPackage = () =>
  useMutation({
    mutationFn: async ({ contentResource, level }: { contentResource?: string; level?: string }) =>
      independentLearningClient.createIndependentLearningPackage(
        CreateIndependentLearningPackageRequest.create({
          resourceName: contentResource,
          level: level,
        }),
      ).response,
    onSuccess: data => {
      data.package.forEach(updatePackage);
    },
  });

export const useTaskItemActivities = (taskItemName: string) =>
  useQuery({
    queryKey: ['taskitemactivities', taskItemName],
    queryFn: async () =>
      activitiesClient
        .listUserTaskItemActivities({
          name: taskItemName,
        })
        .response.then(r => r.activities),
    enabled: Boolean(taskItemName),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: 0, // instantly remove when not needed
  });
