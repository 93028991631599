import { Alert, AlertIcon, Box, Button, Checkbox, Text, VStack } from '@chakra-ui/react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Date } from '@sparx/api/google/type/date';
import { useMutation } from '@tanstack/react-query';
import { reportsClient } from 'api';
import { fetchInterimAwareYear } from 'api/schoolcalendar';
import { getSchoolID } from 'api/sessions';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

import icon from './reporticon.svg';

export const ReportsView = () => {
  return (
    <SuspenseRoute>
      <SuspenseReportsView />
    </SuspenseRoute>
  );
};

const SuspenseReportsView = () => {
  const [includeDemographicData, setIncludeDemographicData] = useState(false);

  const { mutate, isLoading, error } = useMutation({
    mutationFn: async () => {
      const year = await fetchInterimAwareYear();

      return reportsClient.getHomeworkCompletionReport({
        schoolName: `schools/${await getSchoolID()}`,
        includeDemographicData,
        startDate: year?.start ? Date.fromJsDate(year.start) : undefined,
        endDate: year?.end ? Date.fromJsDate(year.end) : undefined,
      }).response;
    },
    onSuccess: data => doDownloadUrl(data.downloadUrl),
  });

  unstable_usePrompt({
    message: 'A report is still generating. Are you sure you want to leave?',
    when: isLoading,
  });

  return (
    <PageContainer>
      <PageTitle title="Reporting" />

      <Text mb={6} fontSize="lg" color="gray.500">
        Downloadable Reports
      </Text>

      <Box maxWidth="700px">
        <Box
          bg="white"
          boxShadow="elevationLow"
          borderRadius="md"
          p={6}
          display="flex"
          alignItems="flex-start"
        >
          <VStack spacing={3} alignItems="flex-start" mr={4}>
            <Text fontSize="lg" color="teal.800" fontWeight="bold">
              Student Activity Report
            </Text>
            <Text pb={2}>
              Get homework completion and student rewards data to help you praise students or
              intervene. Contextual data for each student allows you to filter the report by year,
              class or registration group.
            </Text>
            <Checkbox
              pb={2}
              isChecked={includeDemographicData}
              onChange={e => setIncludeDemographicData(e.target.checked)}
            >
              Include demographic data
              <InfoTooltip text="Provided we have permission to access the data in your school’s MIS, your report will include: pupil premium, free school meals, English as an additional language, and gender data." />
            </Checkbox>
            {Boolean(error) && (
              <Alert borderRadius="md" status="error">
                <AlertIcon />
                <Text>There was an error generating the report. Please try again later.</Text>
              </Alert>
            )}
            <Button
              colorScheme="buttonTeal"
              rightIcon={<FontAwesomeIcon icon={faDownload} />}
              isLoading={isLoading}
              onClick={() => mutate()}
            >
              Download report (Excel)
            </Button>
          </VStack>
          <img src={icon} alt="" />
        </Box>
      </Box>
    </PageContainer>
  );
};

const doDownloadUrl = (url: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = '';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
