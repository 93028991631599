import { Box } from '@chakra-ui/react';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { MisSyncContextProvider } from '@sparx/mis-sync-import';
import { groupsClient, studentsClient, wondeSyncClient } from 'api';
import { queryClient } from 'api/client';
import { groupsQueryKey, studentsQueryKey, useSchool } from 'api/school';
import { useIsInRolloverInterim } from 'api/schoolcalendar';
import { useUserType } from 'api/sessions';
import { PropsWithChildren, useState } from 'react';
import { unstable_usePrompt, useNavigate } from 'react-router-dom';

const debugMisImport = import.meta.env.DEV;

export const MisSyncImportContextWrapper = ({ children }: PropsWithChildren) => {
  const { data: school, refetch: reloadSchool } = useSchool({ suspense: false });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { sparxFeaturesEnabled } = useUserType();
  const inInterim = useIsInRolloverInterim();

  const navigate = useNavigate();
  const onClassManagerClick = () => navigate('/teacher/group');

  // Prompt if the user navigates elsewhere in the app with unsaved changes
  unstable_usePrompt({
    message: 'You have unsaved changes, are you sure you want to leave?',
    when: unsavedChanges,
  });

  return (
    <Box padding={25}>
      <MisSyncContextProvider
        value={{
          school,
          reloadSchool,
          groupsClient,
          groupSubject: StudentGroupType.CLASS_SCIENCE,
          wondeSyncClient,
          studentsClient,
          debug: debugMisImport,
          onUnsavedChanges: (hasChanges: boolean) => setUnsavedChanges(hasChanges),
          onSyncComplete: () => {
            // Invalidate students and student groups so that other pages have the latest data without
            // a page refresh.
            queryClient.invalidateQueries(groupsQueryKey);
            queryClient.invalidateQueries(studentsQueryKey);
          },
          sparxStaffFeaturesEnabled: sparxFeaturesEnabled,
          schoolInInterim: inInterim,
          onClassManagerClick,
        }}
      >
        {children}
      </MisSyncContextProvider>
    </Box>
  );
};
