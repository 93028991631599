import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SubstrandSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { useCurriculums, useTopicWithStrandsLookup } from 'api/content';
import { useMemo } from 'react';
import { findSubject } from 'utils/subjects';

import { useIndependentLearningContext } from '../IndependentLearning';
import { filterTopics } from '../utils';
import { PractiseButton, TopicRow } from './TopicRow';

export const Hierarchy = () => {
  const { createTask, curriculumName } = useIndependentLearningContext();
  const { data: curriculums = [] } = useCurriculums({ suspense: true });

  const currentCurriculum = useMemo(
    () => curriculums.find(curr => curr.curriculum.name === curriculumName) || curriculums[0],
    [curriculums, curriculumName],
  );

  if (!currentCurriculum) {
    throw new Error('No curricula found in IL Hierarchy');
  }

  return (
    <VStack spacing={1} pt={4}>
      {currentCurriculum.strandSummaries.map(strand => (
        <Accordion allowToggle={true} key={strand.strand?.name} width="100%" bg="pageBackground">
          <AccordionItem border="none">
            <AccordionButton as={'div'} fontSize={{ base: 'md', md: 'lg' }} borderRadius="md">
              <AccordionIcon color="teal.600" />
              <Text ml={1} color="gray.400">
                {findSubject(strand.strand?.displayName.toLowerCase() || '')?.icon}
              </Text>
              <Text ml={2} fontWeight={600} color="teal.600">
                {strand.strand?.displayName}
              </Text>
              <Flex grow={1} />
              <PractiseButton
                onClick={() => createTask(strand.strand?.name, strand.strand?.displayName)}
                size={['sm', 'md']}
              />
            </AccordionButton>
            <AccordionPanel px={0} borderTopWidth={1} borderColor="gray.100">
              <SubStrands substrands={strand.substrandSummaries} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </VStack>
  );
};

const SubStrands = ({ substrands }: { substrands: SubstrandSummary[] }) => {
  const { createTask, userState } = useIndependentLearningContext();

  const topicLookup = useTopicWithStrandsLookup({ suspense: true });

  // Filter out substrands which only include topics we filter out (separate-only/higher-only)
  const filteredSubstrands = substrands.filter(sub =>
    sub.substrand?.topicNames.some(name => {
      const topic = topicLookup[name];
      return topic && filterTopics(userState, topic.topic);
    }),
  );

  return (
    <>
      {filteredSubstrands.map(({ substrand }) => (
        <Accordion pl={{ base: 2, md: 4 }} allowToggle={true} key={substrand?.name}>
          <AccordionItem border="none">
            <AccordionButton
              fontSize={{ base: 'sm', md: 'lg' }}
              borderRadius="md"
              textAlign="left"
              px={[3, 4]}
              as={'div'}
            >
              <AccordionIcon />
              <Text ml={3}>{substrand?.displayName}</Text>
              <Flex grow={1} />
              <PractiseButton
                onClick={() => createTask(substrand?.name, substrand?.displayName)}
                size={['xs', 'sm']}
              />
            </AccordionButton>
            <AccordionPanel px={0} borderTopWidth={1} borderColor="gray.100">
              <Topics topics={substrand?.topicNames || []} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </>
  );
};

const Topics = ({ topics }: { topics: string[] }) => {
  const { userState } = useIndependentLearningContext();
  const topicLookup = useTopicWithStrandsLookup({ suspense: true });

  const topicsWithStrands = useMemo(
    () => topics.map(name => topicLookup[name]).filter(t => t && filterTopics(userState, t.topic)),
    [topicLookup, topics, userState],
  );

  return (
    <>
      <VStack
        py={0}
        pl={{ base: 2, md: 12 }}
        pr={2}
        spacing={0.5}
        divider={<Divider m={0} mt={0} />}
      >
        {topicsWithStrands.map(topic => {
          return (
            <Box width="100%" key={topic.topic.name}>
              <TopicRow topic={topic} />
            </Box>
          );
        })}
      </VStack>
    </>
  );
};
