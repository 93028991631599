import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { StaffContextProvider } from '@sparx/staff-manager';
import { schoolActionsClient, useSchoolStaffClient } from 'api';
import { queryClient } from 'api/client';
import { useSchool } from 'api/school';
import { getSchoolID, useSession } from 'api/sessions';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import React, { PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { articleKeyContacts } from 'utils/knowledgeBaseArticles';

export const SuspenseStaffContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { data: user } = useSession({ suspense: true });
  const { data: school } = useSchool({ suspense: true });
  const { data: schoolStaffClient } = useSchoolStaffClient({ suspense: true });

  if (!schoolStaffClient) {
    // Should not happen due to the suspense
    return null;
  }

  return (
    <StaffContextProvider
      value={{
        school,
        currentUserID: user?.sparxUserId || user?.userId,
        queryClient,
        staffClient: schoolStaffClient,
        schoolActionsClient: schoolActionsClient,
        defaultProduct:
          import.meta.env.VITE_SPARX_PRODUCT === 'SPARX_TEACHING'
            ? Product.SPARX_TEACHING
            : Product.SPARX_SCIENCE,
        showTrainingProgress: true,

        knowledgeBaseArticleURL: articleKeyContacts,

        getSchoolID,
        onSelectStaff: (name: string) => navigate(`/teacher/${name}/details`),
        onStaffDeleted: toastMessage => navigate(`/teacher/staff`, { state: { toastMessage } }),
        onStaffCreated: toastMessage => navigate(`/teacher/staff`, { state: { toastMessage } }),
        getInfoTooltip: text => <InfoTooltip text={text} />,
        onSwitchTab: idx => navigate(`/teacher/staff${idx === 1 ? '/keycontacts' : ''}`),
        useNavigationMessage: () => {
          const location = useLocation();
          return location.state?.toastMessage;
        },

        styles: {
          linkColor: 'buttonTeal.600',
          editIconColor: 'teal.600',
          headerBackgroundColor: 'blue.800',
          tableSortOptions: {
            upIcon: faCaretUp,
            downIcon: faCaretDown,
            hoverStyle: { background: 'blue.900', cursor: 'pointer' },
          },
        },
      }}
    >
      {children}
    </StaffContextProvider>
  );
};
