import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  faBars,
  faCaretDown,
  faCheck,
  faCog,
  faComment,
  faCopy,
  faExternalLink,
  faGift,
  faGraduationCap,
  faQuestionCircle,
  faRightFromBracket,
  faShuffle,
  faUser,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { XpState } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useIntercom } from '@sparx/intercom';
import { logout, selectSchoolURL } from 'api';
import { useIsInLesson, useLeaveLesson } from 'api/lessons';
import { useSchool } from 'api/school';
import { setJamieMode, setSparxFeatures, useSession, useUserType } from 'api/sessions';
import { useCurrentStaffUser } from 'api/staff';
import { UserSettings, useUserSetting } from 'api/usersettings';
import { useWhatsNewPosts } from 'api/whatsnew';
import { useUserXpState } from 'api/xp';
import { BackLink, useNavigationStyle } from 'app/BackLink';
import notificationDot from 'app/images/notification_dot_small.svg';
import logo from 'app/images/science_white.svg';
import { ProductSwitch } from 'app/ProductSwitch';
import { useClientEvent } from 'components/ClientEventProvider';
import { Copyable, useCopyableValue } from 'components/copyable/Copyable';
import { StudentFeedbackModal } from 'components/feedback/StudentFeedback';
import { CurrentUserDisplayName } from 'components/Names';
import { OmniSearch } from 'components/search/OmniSearch';
import { SimpleAlert } from 'components/simplealert/SimpleAlert';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { isAfter, parseJSON } from 'date-fns';
import React, { PropsWithChildren, ReactElement, ReactNode, useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { urlKnowledgeBase } from 'utils/knowledgeBaseArticles';
import { SchoolSetupHeader } from 'views/getting-started/components/SchoolSetupHeader';
import { getUserLandingPage } from 'views/landing/LandingView';

import { ClassSelection } from './ClassSelection';

export const Header = () => {
  const { data: user } = useSession();
  const { isTeacher, jamieMode } = useUserType();

  const location = useLocation();
  const isOnTeacherPage = location.pathname.startsWith('/teacher');
  const isOnTeacherLessonPage = Boolean(location.pathname.match(/^\/teacher\/lessons\/.+$/));
  const showStudentSearch = isOnTeacherPage && !isOnTeacherLessonPage;

  const isInLesson = useIsInLesson();
  const { data: xpState } = useUserXpState();

  const navigationStyle = useNavigationStyle();
  if (navigationStyle === 'hidden') {
    return null;
  }

  return (
    <>
      {jamieMode && <SchoolNameHeader />}
      <Flex
        bgGradient="linear(to-r, teal.600, teal.500)"
        h={['50px', '60px']}
        display="flex"
        flexShrink={0}
        alignItems="center"
      >
        <BackLink />
        <Box px={[2, 4, 5]}>
          <Link to={isInLesson ? '/lesson' : getUserLandingPage(user)}>
            <Heading color="white" fontSize={23} as="h1">
              <Image src={logo} alt="Sparx Science" height={['20px', '27px']} mt={['3px', '4px']} />
            </Heading>
          </Link>
        </Box>
        {isTeacher && <ClassSelection />}
        <Spacer />
        <Box pl={2} display="flex" alignItems="center" height="100%">
          {!isTeacher ? (
            <>
              <StudentFeedbackButton />
              {xpState && xpState.currentXp > 0 && (
                <Box pr={1}>
                  <XpDisplay xpState={xpState} />
                </Box>
              )}
              <Text
                fontWeight="bold"
                color="white"
                ml={3}
                mr={6}
                display={['none', 'none', 'inline-block']}
              >
                <CurrentUserDisplayName />
              </Text>
              <StudentHeaderMenu />
            </>
          ) : (
            <>
              {xpState && !isOnTeacherPage && <XpDisplay xpState={xpState} />}
              <SchoolSetupHeader />
              <OmniSearch hidden={!showStudentSearch} />
              <KBButton />
              <TeacherHeaderMenu />
            </>
          )}
        </Box>
      </Flex>
    </>
  );
};

interface HeaderMenuButtonProps {
  isLoading?: boolean;
  sm: ReactNode;
  md: ReactNode;
  lg?: ReactNode;
  icon?: ReactElement;
}

const HeaderMenuButton = ({ isLoading, sm, md, lg, icon }: HeaderMenuButtonProps) => (
  <MenuButton
    as={Button}
    _hover={{ bg: 'blackAlpha.400' }}
    _active={{ bg: 'blackAlpha.500' }}
    bg="blackAlpha.300"
    color="white"
    rightIcon={icon}
    isLoading={isLoading}
    whiteSpace="nowrap"
    position="relative"
    height="100%"
    borderRadius={0}
    px={[4, 5]}
  >
    <Text as="span" display={['inline', 'inline', 'none']}>
      {sm}
    </Text>
    <Text as="span" display={['none', 'none', 'inline', 'none']}>
      {md}
    </Text>
    <Text as="span" display={['none', 'none', 'none', 'inline']}>
      {lg || md}
    </Text>
  </MenuButton>
);

const TeacherHeaderMenu = () => {
  const { isLoading } = useSession();
  const isInLesson = useIsInLesson();
  const { isActuallySparxStaff } = useUserType();
  const { data: staffUser } = useCurrentStaffUser();

  return (
    <Menu lazyBehavior="keepMounted" variant="headerMenu" isLazy={true} gutter={0}>
      <HeaderMenuButton
        isLoading={isLoading}
        icon={<FontAwesomeIcon icon={faCaretDown} />}
        sm={<FontAwesomeIcon icon={faUser} />}
        md={
          <>
            <CurrentUserDisplayName />
          </>
        }
      />
      <MenuList>
        <UserNameMenuItem />
        <CurrentSchool />
        <SparxStaffMenuItems />
        <MenuDivider />
        {isInLesson && <LessonLeaveMenuItem />}
        <TeacherFeedbackMenuItem />
        {!isInLesson && <WhatsNewMenuItem />}
        {!isInLesson && <TrainingMenuItem />}
        <SettingsMenuItem isTeacher />
        <MenuDivider />
        {(staffUser?.multipleSchools || isActuallySparxStaff) && <SwitchSchoolMenuItem />}
        <SignOutMenuItem />
      </MenuList>
    </Menu>
  );
};

const StudentHeaderMenu = () => {
  const isInLesson = useIsInLesson();

  return (
    <Menu lazyBehavior="keepMounted" variant="headerMenu" isLazy={true} gutter={0}>
      <HeaderMenuButton
        sm={<FontAwesomeIcon icon={faBars} size="xl" />}
        md={
          <Flex alignItems="center">
            <Text as="span" mr={4}>
              Menu
            </Text>
            <FontAwesomeIcon icon={faBars} size="xl" />
          </Flex>
        }
      />
      <MenuList>
        <UserNameMenuItem mb={3} hideMd={true} />
        <MenuDivider display={['block', 'block', 'none']} />
        <SettingsMenuItem />
        <MenuDivider />
        {isInLesson && (
          <>
            <LessonLeaveMenuItem />
            <MenuDivider />
          </>
        )}
        {!isInLesson && <ProductSwitch />}
        <SignOutMenuItem />
      </MenuList>
    </Menu>
  );
};

const SparxStaffMenuItems = () => {
  const { isSparxStaff, isActuallySparxStaff } = useUserType();

  if (!isActuallySparxStaff) {
    return null;
  }

  return (
    <>
      <MenuDivider />
      <SparxFeatures />
      <CopyLinkMenuItem />
      <FakeNamesMenuItem />
      {isSparxStaff && <JamieMode />}
    </>
  );
};

const SparxFeatures = () => {
  const { sparxFeaturesEnabled } = useUserType();

  return (
    <Box pl={5} mr={3} mb={1} mt={3}>
      <HStack spacing={2}>
        <Copyable
          value={import.meta.env.VITE_RELEASE_VERSION || 'Unknown'}
          displayValue={(import.meta.env.VITE_RELEASE_VERSION || 'Unknown').slice(0, 7)}
        />
        <Copyable
          value={import.meta.env.VITE_RELEASE_TIMESTAMP || 'Unknown'}
          displayValue={import.meta.env.VITE_RELEASE_TIMESTAMP || 'Unknown'}
        />
      </HStack>
      <Box my={3}>
        <Text fontSize="sm" color="gray.700" fontWeight="bold">
          Sparx Staff Features
        </Text>
        <ToggleSwitch
          checked={sparxFeaturesEnabled}
          setChecked={setSparxFeatures}
          leftLabel="Off"
          rightLabel="On"
          offColour="gray.400"
          enableColourBG={true}
        />
      </Box>
    </Box>
  );
};

const JamieMode = () => {
  const { jamieMode } = useUserType();

  return (
    <Flex alignItems="center" pl={4} mr={3} mb={3} mt={2}>
      <Switch isChecked={jamieMode} onChange={() => setJamieMode(!jamieMode)} />
      <Text fontSize="sm" color="gray.500" ml={2}>
        Jamie Mode&trade;
      </Text>
    </Flex>
  );
};

const EnvBox = ({ color, children }: PropsWithChildren<{ color: string }>) => (
  <Box bg={`${color}.900`} color={`${color}.200`} px={3} py={2} mx={-3} my={-2} fontWeight="bold">
    {children}
  </Box>
);

const environments: Record<string, () => React.ReactNode> = {
  prod: () => <EnvBox color="red">Production</EnvBox>,
  test1: () => <EnvBox color="yellow">Test 1</EnvBox>,
  test2: () => <EnvBox color="green">Test 2</EnvBox>,
  dev: () => <EnvBox color="blue">Dev</EnvBox>,
};

const SchoolNameHeader = () => {
  const { data: school, isLoading: isLoadingSchool } = useSchool({ suspense: false });

  return (
    <Flex
      px={3}
      py={2}
      bg="teal.800"
      color="white"
      lineHeight="1em"
      fontSize="sm"
      overflow="hidden"
    >
      {environments[window.settings?.ENV_NAME || 'dev']?.()}
      <Spacer />
      <Text mr={3} color="whiteAlpha.700">
        Current school:
      </Text>
      <Skeleton isLoaded={!isLoadingSchool}>
        <Text fontWeight="bold">{school?.displayName}</Text>
      </Skeleton>
    </Flex>
  );
};

const UserNameMenuItem = ({ mb, hideMd }: { mb?: number; hideMd?: boolean }) => (
  <Box
    display={['block', 'block', hideMd ? 'none' : 'block', 'none']}
    fontWeight="bold"
    mx={5}
    mt={2}
    mb={mb}
    color="gray.600"
  >
    <CurrentUserDisplayName />
  </Box>
);

const CurrentSchool = () => {
  const { data: school, isLoading: isLoadingSchool } = useSchool({ suspense: false });
  return (
    <Box px={5} mb={4} mt={2}>
      <Text fontSize="sm" color="gray.600">
        Signed into:
      </Text>
      <Skeleton isLoaded={!isLoadingSchool}>
        <Text fontWeight="bold" color="gray.700" lineHeight="1em" maxWidth="200px">
          {school?.displayName}
        </Text>
      </Skeleton>
    </Box>
  );
};

const LessonLeaveMenuItem = () => {
  const { mutateAsync } = useLeaveLesson();

  return (
    <SimpleAlert
      header="Leave lesson"
      body="Are you sure you want to leave this lesson?"
      onConfirm={mutateAsync}
      confirmText="Leave lesson"
    >
      {onOpen => (
        <MenuItem
          onClick={onOpen}
          icon={
            <Text color="red.500">
              <FontAwesomeIcon icon={faRightFromBracket} fixedWidth={true} />
            </Text>
          }
        >
          Leave lesson
        </MenuItem>
      )}
    </SimpleAlert>
  );
};

const SignOutMenuItem = () => (
  <SimpleAlert
    header="Sign out"
    body="Are you sure you want to sign out of Sparx Science?"
    onConfirm={logout}
    confirmText="Sign out"
  >
    {onOpen => (
      <MenuItem
        onClick={onOpen}
        icon={<FontAwesomeIcon icon={faRightFromBracket} fixedWidth={true} />}
      >
        Sign out
      </MenuItem>
    )}
  </SimpleAlert>
);

const TrainingMenuItem = () => (
  <MenuItem
    as={Link}
    to={'/teacher/training'}
    icon={<FontAwesomeIcon icon={faGraduationCap} fixedWidth={true} />}
  >
    Your Training
  </MenuItem>
);

const WhatsNewMenuItem = () => {
  const { data: lastViewed, isSuccess: settingLoaded } = useUserSetting(
    UserSettings.whatsNewLastViewed,
    {
      suspense: false,
    },
  );
  const { data: user, isSuccess: userLoaded } = useSession();

  const lastViewedDate = parseJSON(
    lastViewed || (user?.firstLogin && Timestamp.toDate(user.firstLogin)) || new Date(),
  );

  const { data: posts, isSuccess: postsLoaded } = useWhatsNewPosts({ suspense: false });

  const hasNew =
    settingLoaded &&
    userLoaded &&
    postsLoaded &&
    posts[0].attributes.publishedAt &&
    isAfter(parseJSON(posts[0].attributes.publishedAt), lastViewedDate);

  return (
    <MenuItem
      as={Link}
      to={'/teacher/whats-new'}
      icon={
        <Text position="relative">
          <FontAwesomeIcon icon={faGift} fixedWidth={true} />
          {hasNew && (
            <Image src={notificationDot} position="absolute" w="8px" top="-1px" right="-3px" />
          )}
        </Text>
      }
    >
      What&apos;s new?
    </MenuItem>
  );
};

const SwitchSchoolMenuItem = () => {
  const { sendEvent } = useClientEvent();
  return (
    <MenuItem
      as={Link}
      to={`${selectSchoolURL}/?app=sparx_science&noredirect=1&route=${encodeURIComponent(window.location.origin)}`}
      closeOnSelect={false}
      icon={<FontAwesomeIcon icon={faShuffle} fixedWidth={true} />}
      onClick={() => {
        sendEvent({ category: 'header-menu', action: 'click_switch_school' }, undefined, {
          immediate: true,
        });
      }}
    >
      Switch school
    </MenuItem>
  );
};

const CopyLinkMenuItem = () => {
  const { data: school } = useSchool({ suspense: false });
  const { copied, onCopy } = useCopyableValue(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('school', school?.name?.replace('schools/', '') ?? '');
    return url.toString();
  });

  return (
    <MenuItem
      onClick={onCopy}
      closeOnSelect={false}
      icon={<FontAwesomeIcon icon={copied ? faCheck : faCopy} fixedWidth={true} />}
    >
      Copy URL
    </MenuItem>
  );
};

const FakeNamesMenuItem = () => (
  <MenuItem
    onClick={() => window.open(window.location.href, 'ANONYMOUS_MODE')}
    closeOnSelect={false}
    icon={<FontAwesomeIcon icon={faUserSecret} fixedWidth={true} />}
  >
    Anonymous mode
  </MenuItem>
);

export const teacherFeedbackFormURL = (name: string, email: string) =>
  `https://docs.google.com/forms/d/e/1FAIpQLSdAoVHxC3_b98QnE50kzqy-hm9_HNM3iQ-O7tYAW9E3t7-sFQ/viewform?usp=pp_url&entry.1548974478=${encodeURIComponent(email)}&entry.928010194=${encodeURIComponent(name)}`;
const TeacherFeedbackMenuItem = () => {
  const { sendEvent } = useClientEvent();
  const isInLesson = useIsInLesson();
  const showFeedbackButton = !isInLesson;

  const { data: staff } = useCurrentStaffUser();

  const name = staff ? staff?.givenName + ' ' + staff?.familyName : '';
  const email = staff?.emailAddress || '';

  return (
    showFeedbackButton && (
      <MenuItem
        as={Link}
        to={teacherFeedbackFormURL(name, email)}
        target="_blank"
        rel="external noopener"
        onClick={() => {
          sendEvent({ category: 'header-menu', action: 'click_teacher_feedback' });
        }}
        icon={<FontAwesomeIcon icon={faComment} fixedWidth={true} />}
      >
        Give feedback{' '}
        <Box as="span" color="gray.400" ml={1}>
          <FontAwesomeIcon icon={faExternalLink} size="sm" />
        </Box>
      </MenuItem>
    )
  );
};

const StudentFeedbackButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isInLesson = useIsInLesson();

  return (
    <>
      <FeedbackButton visible={!isInLesson} onOpen={onOpen} />
      <StudentFeedbackModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const FeedbackButton = ({ visible, onOpen }: { visible: boolean; onOpen: () => void }) => (
  <Button
    visibility={visible ? 'visible' : 'hidden'}
    opacity={visible ? 1 : 0}
    transition="opacity 0.5s"
    mr={2}
    colorScheme="whiteAlpha"
    color="whiteAlpha.800"
    variant="ghost"
    onClick={onOpen}
    size={['sm', 'md']}
  >
    <FontAwesomeIcon icon={faComment} />
    <Text display={{ base: 'none', xl: 'inline' }} as="span" pl={2}>
      Give feedback
    </Text>
  </Button>
);

const KBButton = () => {
  const { sendEvent } = useClientEvent();
  const { intercomEnabled, show, showArticle, showSpace, isOpenRef, hide } = useIntercom();
  const props = intercomEnabled
    ? {
        onClick: () => {
          sendEvent(
            { category: 'intercom', action: 'click_header_button' },
            { widgetOpen: isOpenRef.current ? 'true' : 'false' },
          );

          if (isOpenRef.current) {
            // If already open, close the widget
            hide();
            return;
          }

          // Show the home tab
          showSpace('home');
          // There seems to be a bug where sometimes the intercom widget won't show, this timout works around that.
          // It seems to happen when an article link is opened, closed and then the help button clicked. Events show the widget open and instantly close.
          setTimeout(() => {
            if (!isOpenRef.current) {
              show();
            }
          }, 100);
        },
      }
    : {
        target: '_blank',
        as: Link,
        to: urlKnowledgeBase,
      };
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // automatically open Intercom when we have the ?support query param
  useEffect(() => {
    // Only trigger if we have intercom and are not on the landing page
    if (intercomEnabled && location.pathname !== '/') {
      if (searchParams.has('support')) {
        const article = searchParams.get('support');

        // remove the support param now that we have read it.
        setSearchParams(prev => {
          prev.delete('support');
          return prev;
        });

        if (!article) {
          // just open intercom
          showSpace('home');
        } else {
          const num = Number(article);
          if (!isNaN(num)) {
            // Open the relevant article
            // https://developers.intercom.com/installing-intercom/web/methods/#intercomshowarticle-articleid
            showArticle(num);
          } else {
            // Assume it's the name of a 'space' and open that.
            // https://developers.intercom.com/installing-intercom/web/methods/#intercomshowspace-spacename
            showSpace(article);
          }
        }
      }
    }
  }, [intercomEnabled, location, searchParams, setSearchParams, show, showArticle, showSpace]);

  return (
    <Button
      mr={2}
      colorScheme="whiteAlpha"
      variant="ghost"
      size={['sm', 'md']}
      color="white"
      {...props}
    >
      <FontAwesomeIcon icon={faQuestionCircle} />
      <Text display={{ base: 'none', xl: 'inline' }} as="span" pl={2}>
        Help & Support
      </Text>
    </Button>
  );
};

const SettingsMenuItem = ({ isTeacher }: { isTeacher?: boolean }) => {
  const { data: user } = useSession();
  const { isActuallySparxStaff } = useUserType();
  const userId = user?.sparxUserId || user?.userId;
  if (!userId || isActuallySparxStaff) {
    return null;
  }
  return (
    <MenuItem
      as={Link}
      to={isTeacher ? `teacher/staff/${userId}/details` : '/user-settings'}
      icon={<FontAwesomeIcon icon={faCog} fixedWidth={true} />}
    >
      My Settings
    </MenuItem>
  );
};

const XpDisplay = ({ xpState }: { xpState: XpState }) => {
  if (!xpState.currentXp) {
    return null;
  }
  return (
    <ChakraLink
      as={Link}
      to="/rewards"
      color="white"
      fontSize={{ base: 'xs', sm: 'md' }}
      textAlign="center"
      whiteSpace="nowrap"
    >
      {xpState.currentXp.toLocaleString('en-GB')} XP
    </ChakraLink>
  );
};
