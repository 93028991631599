import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faChevronDown, faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurriculums, useTopicWithStrandsLookup } from 'api/content';
import { Hide, Show } from 'components/chakraExports';
import React, { useMemo, useState } from 'react';
import { LevelChip } from 'views/independentlearning/components/LevelChip';
import { useTopicSearch } from 'views/planner/components/topicSearch';

import { ContinuePractice } from './components/ContinuePracticeCard';
import { Hierarchy } from './components/Hierarchy';
import { PersonalPractice } from './components/PersonalPracticeCard';
import { TopicRow } from './components/TopicRow';
import { useIndependentLearningContext } from './IndependentLearning';
import { filterTopics } from './utils';

export const CreateATask = () => {
  return (
    <VStack align="stretch" spacing={{ base: 4, md: 6 }}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 4, md: 6 }}>
        <PersonalPractice />
        <ContinuePractice />
      </Stack>
      <ChooseTopic />
    </VStack>
  );
};

const ChooseTopic = () => {
  const { userState, curriculumName, setCurriculumName } = useIndependentLearningContext();
  const { data: curriculums = [] } = useCurriculums({ suspense: true });

  const [search, setSearch] = useState('');

  const topicLookup = useTopicWithStrandsLookup({ suspense: true });
  const topics = useMemo(
    () => Object.values(topicLookup).filter(t => filterTopics(userState, t.topic)),
    [topicLookup, userState],
  );

  const searchedTopics = useTopicSearch(
    topics,
    { curriculum: curriculumName, search },
    { enabled: search !== '' },
  );

  const curriculumSelector = (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="center"
      mt={{ base: 2, sm: 0 }}
      alignSelf="flex-start"
      gap={2}
      wrap={{ base: 'wrap', md: 'nowrap' }}
    >
      <Text fontSize="sm" color="gray.500" textAlign="right" mr={{ base: 2, sm: 0, md: 2 }}>
        Curriculum
        <Text as="span" display={{ base: 'inline', xs: 'none' }}>
          :
        </Text>
      </Text>
      <Select width={160} value={curriculumName} onChange={e => setCurriculumName(e.target.value)}>
        {curriculums.map(curr => (
          <option key={curr.curriculum.name} value={curr.curriculum.name}>
            {curr.curriculum.displayName}
          </option>
        ))}
      </Select>
    </Flex>
  );

  const levelSelector = <LevelSelector />;

  const searchBar = (
    <HStack width="100%" justifyContent="flex-end">
      <Show above="sm">
        <Text fontSize="sm" color="gray.500" textAlign="right" whiteSpace="nowrap">
          Search topics:
        </Text>
      </Show>
      <InputGroup>
        <Input
          minWidth={200}
          flex={1}
          backgroundColor="white"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search topic names or codes"
          pr={10}
        />
        <InputRightElement width={10}>
          {search != '' ? (
            <Button
              size="sm"
              color="gray.700"
              variant="ghost"
              borderRadius={100}
              onClick={() => setSearch('')}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
          ) : (
            <FontAwesomeIcon fill="gray.200" icon={faSearch} />
          )}
        </InputRightElement>
      </InputGroup>
    </HStack>
  );

  return (
    <Box bg="white" boxShadow="elevationLow" borderRadius="lg" p={{ base: 3, sm: 6, md: 8 }}>
      <Box px={[3, 0]} pt={[3, 0]}>
        <Heading fontSize="xl">Choose a topic</Heading>
        <Flex justifyContent="space-between" mb={2} mt={2} gap={4} alignItems="center">
          <Show above="lg">
            {searchBar}
            {levelSelector}
            {curriculumSelector}
          </Show>
        </Flex>
        <Hide above="lg">
          <Stack mb={2} direction={{ base: 'column', sm: 'row' }} justifyContent={'space-between'}>
            {levelSelector}
            {curriculumSelector}
          </Stack>
          <Divider />
          <Box mt={2}>{searchBar}</Box>
        </Hide>
      </Box>
      {search === '' ? (
        <Hierarchy />
      ) : (
        <VStack alignItems="flex-start" p={4}>
          {searchedTopics.length === 0 && (
            <Text width="100%" textAlign="center" color="gray.500">
              No topics found
            </Text>
          )}
          {searchedTopics.map((t, idx) => (
            <Box width="100%" key={t.topic.name}>
              {idx !== 0 && <Divider mb={2} />}
              <TopicRow topic={t} searchResult={true} />
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

const levelCopy: Record<string, string> = {
  '1': 'Practice the basics of each topic at a gentle pace.',
  '2': 'Practice the basics of each topic.',
  '3': 'Practice the basics and try some more challenging questions.',
  '4': 'Practice with the most challenging questions.',
};

const LevelSelector = () => {
  const { level, setLevel, defaultLevel } = useIndependentLearningContext();
  return (
    <Flex
      direction={{ base: 'row' }}
      alignItems="center"
      justifyContent="center"
      mt={{ base: 2, sm: 0 }}
      alignSelf="flex-start"
      gap={2}
      wrap={{ base: 'wrap', md: 'nowrap' }}
    >
      <Text fontSize="sm" color="gray.500" textAlign="right" mr={{ base: 2, sm: 0, md: 2 }}>
        Level
        <Text as="span" display={{ base: 'inline', xs: 'none' }}>
          :
        </Text>
      </Text>
      <Menu placement="bottom-start" closeOnSelect>
        <MenuButton
          borderWidth="1px"
          borderColor="gray.200"
          backgroundColor="transparent"
          borderRadius="5"
          p="2"
          w={180}
          height={'40px'}
        >
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <LevelChip level={level} />
            {defaultLevel === level && (
              <Text color="gray.500" fontSize="sm">
                (Default)
              </Text>
            )}
            <FontAwesomeIcon icon={faChevronDown} size={'xs'} />
          </Stack>
        </MenuButton>
        <MenuList p={0}>
          <Stack dir="column" spacing={0} maxW="90vw" p={0} width={400}>
            {['1', '2', '3', '4'].map(l => (
              <MenuItem
                key={l}
                display="flex"
                alignItems="center"
                p={2}
                onClick={() => setLevel(l)}
                gap={2}
              >
                <LevelChip level={l} />
                <Text fontSize="sm">
                  {levelCopy[l]} {defaultLevel === l && <b> This is your default level.</b>}
                </Text>
              </MenuItem>
            ))}
          </Stack>
        </MenuList>
      </Menu>
    </Flex>
  );
};
