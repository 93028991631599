import { Box, MenuDivider, MenuGroup, MenuItem, Text } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBarChart,
  faCalendar,
  faChalkboardTeacher,
  faChartLine,
  faExternalLink,
  faFileLines,
  faGear,
  faGraduationCap,
  faHome,
  faList,
  faTable,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useSchool } from 'api/school';
import { FeatureFlag, useBooleanFlag, useUserType } from 'api/sessions';
import { useNavigationStyle } from 'app/BackLink';
import { TeacherContentMoreMenu } from 'app/TeacherContentMoreMenu';
import { NotFound } from 'components/errorpages/NotFound';
import { LargeLoading } from 'components/loading/LargeLoading';
import { RolePrompt } from 'components/roleprompt';
import React, { PropsWithChildren } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { AnswerHistoryView } from 'views/answerhistory/AnswerHistoryView';
import { AssignmentHandInView } from 'views/assignments/AssignmentHandInView';
import { AssignmentListView } from 'views/assignments/AssignmentListView';
import { GettingStartedRoute } from 'views/getting-started/GettingStartedSteps';
import { NewYearSetupRoute } from 'views/getting-started/NewYearSetup';
import { GroupDetailView } from 'views/groups/GroupDetailView';
import { GroupListView } from 'views/groups/GroupListView';
import { HandInView } from 'views/handin/HandInView';
import { InsightsDisplayView } from 'views/insights/InsightsDisplayView';
import { InsightsPrintView } from 'views/insights/InsightsPrintView';
import { InsightsView } from 'views/insights/InsightsView';
import { ArchivedLessonView } from 'views/lessons/ArchivedLessonView';
import { LessonArchiveListView } from 'views/lessons/LessonArchiveListView';
import { LessonsListView } from 'views/lessons/LessonsListView';
import { LessonView } from 'views/lessons/LessonView';
import { MisSyncImportView } from 'views/mis-sync-import/MisSyncImportView';
import { PlannerView } from 'views/planner/PlannerView';
import { ReportsView } from 'views/reports/ReportsView';
import { AcademicYearView } from 'views/schoolsettings/AcademicYearView';
import { SchoolSettingsView } from 'views/schoolsettings/SchoolSettingsView';
import { SolArchiveList } from 'views/sol/SolArchive';
import { SolBuilderView } from 'views/sol/SolBuilderView';
import { SolHomeView } from 'views/sol/SolHomeView';
import { SolList } from 'views/sol/SolList';
import { SolTemplates } from 'views/sol/SolTemplates';
import { SuspenseStaffContextProvider } from 'views/staffv2/Context';
import { KeyContactListView } from 'views/staffv2/KeyContactListView';
import { StaffDetailView } from 'views/staffv2/StaffDetailView';
import { StaffListView } from 'views/staffv2/StaffListView';
import { StudentDetailView } from 'views/students/StudentDetailView';
import { StudentListView } from 'views/students/StudentListView';
import { StudentSummaryView } from 'views/students/StudentSummaryView';
import { SuspenseStudentView } from 'views/students/StudentView';
import { TeacherHomeView } from 'views/teacherhome/TeacherHomeView';
import { TeacherTrainingView } from 'views/teachertraining/TeacherTrainingView';
import { TrainingContext } from 'views/teachertraining/TrainingContext';
import { WhatsNewPromotedDialog } from 'views/whatsnew/WhatsNewPromotedDialog';
import { WhatsNewView } from 'views/whatsnew/WhatsNewView';

import { IntercomContext } from './Intercom';
import { SidebarButton } from './Sidebar';

export const TeacherContent = () => {
  const { isLoading, isTeacher, isSparxStaff } = useUserType();
  const navigationHidden = useNavigationStyle() === 'hidden';
  const sidebarHidden = useNavigationStyle() === 'sidebar-hidden';
  const lessonsEnabled = useBooleanFlag(FeatureFlag.Lessons);
  const school = useSchool({ suspense: false, enabled: isTeacher || isLoading });

  const assessmentsEnabled = school?.data?.products.includes(Product.SPARX_ASSESSMENTS);

  if (!isLoading && !isTeacher) {
    return <NotFound />;
  }
  if (isLoading || school.isLoading) {
    return <LargeLoading />;
  }

  const routes = (
    <Routes>
      <Route path="/" element={<TeacherHomeView />} />
      <Route path="/student" element={<StudentListView />} />
      <Route path="/student/:studentID" element={<SuspenseStudentView />}>
        <Route index element={<Navigate to="details" replace />} />
        <Route path="details" element={<StudentDetailView />} />
        <Route path="summary" element={<StudentSummaryView />} />
      </Route>
      <Route path="/group" element={<GroupListView />} />
      <Route path="/group/:groupID/details" element={<GroupDetailView />} />
      <Route path="/handin" element={<HandInView />} />
      <Route path="/handin/answers" element={<AnswerHistoryView />} />
      <Route path="/insights" element={<InsightsView />} />
      <Route path="/insights/display" element={<InsightsDisplayView />} />
      <Route path="/insights/print" element={<InsightsPrintView />} />
      {isSparxStaff && (
        <>
          <Route path="/assignments" element={<AssignmentListView />} />
          <Route path="/assignments/handin" element={<AssignmentHandInView />} />
        </>
      )}
      <Route path="/planner" element={<PlannerView />} />
      <Route path="/staff" element={<StaffListView />} />
      <Route path="/staff/keycontacts" element={<KeyContactListView />} />
      <Route path="/staff/new" element={<StaffDetailView isNew={true} />} />
      <Route path="/staff/:staffID/details" element={<StaffDetailView isNew={false} />} />
      <Route path="/sol" element={<SolHomeView />}>
        <Route index element={<Navigate to="thisyear" replace />} />
        <Route path="thisyear" element={<SolList year="current" />} />
        <Route path="nextyear" element={<SolList year="next" />} />
        <Route path="templates" element={<SolTemplates />} />
        <Route path="archive" element={<SolArchiveList />} />
      </Route>
      <Route path="/sol/view/:solID" element={<SolBuilderView readonly={true} />} />
      <Route path="/sol/edit/:solID" element={<SolBuilderView />} />
      <Route path="/reports" element={<ReportsView />} />
      {lessonsEnabled && (
        <>
          <Route path="/lessons" element={<LessonsListView />} />
          <Route path="/lessons/:lessonID" element={<LessonView />} />
          <Route path="/lessons/archive" element={<LessonArchiveListView />} />
          <Route path="/lessons/archive/:lessonID" element={<ArchivedLessonView />} />
        </>
      )}
      <Route path="/mis-sync-import" element={<MisSyncImportView />} />
      <Route path="/training" element={<TeacherTrainingView />} />
      <Route path="/whats-new" element={<WhatsNewView />} />
      <Route path="/school-settings" element={<SchoolSettingsView />}>
        <Route index element={<Navigate to="academic-year" replace />} />
        <Route path="academic-year" element={<AcademicYearView />} />
      </Route>
      {NewYearSetupRoute}
      {GettingStartedRoute}
      <Route path="*" element={<NotFound back="/teacher" />} />
    </Routes>
  );

  const sidebar = (
    <Box
      backgroundColor="white"
      borderRightColor="gray.200"
      width={110}
      padding={2}
      overflowY="auto"
    >
      <SidebarButton title="Home" icon={faHome} to="/teacher" />
      {lessonsEnabled && (
        <SidebarButton title="Lessons" icon={faChalkboardTeacher} to="/teacher/lessons" />
      )}
      <SidebarButton
        title="Planner"
        icon={faCalendar}
        to="/teacher/planner"
        preserveSearch={['group']}
      />
      <SidebarButton
        title="Hand-in"
        icon={faBarChart}
        to="/teacher/handin"
        preserveSearch={['group', 'assignment']}
      />
      <SidebarButton
        title="Insights"
        icon={faChartLine}
        to="/teacher/insights"
        preserveSearch={['group', 'assignment']}
      />
      <SidebarButton title="Reporting" icon={faTable} to="/teacher/reports" />
      <TeacherContentMoreMenu>
        <MenuGroup color="gray.600">
          <MenuItem icon={MoreMenuIcon(faUsers)} as={Link} to="/teacher/student">
            Student Manager
          </MenuItem>
          <MenuItem icon={MoreMenuIcon(faChalkboardTeacher)} as={Link} to="/teacher/group">
            Class Manager
          </MenuItem>
          <MenuItem icon={MoreMenuIcon(faGraduationCap)} as={Link} to="/teacher/staff">
            Staff Manager
          </MenuItem>
          <MenuItem icon={MoreMenuIcon(faFileLines)} as={Link} to="/teacher/sol">
            Scheme of Learning
          </MenuItem>
          <MenuItem icon={MoreMenuIcon(faGear)} as={Link} to="/teacher/school-settings">
            School Settings
          </MenuItem>
        </MenuGroup>
        {assessmentsEnabled && (
          <>
            <MenuDivider />
            <MenuItem
              icon={MoreMenuIcon(faFileLines)}
              as={Link}
              target="_blank"
              to={
                (window.settings?.SPARX_ASSESSMENTS_URL || 'https://app.sparxassessments.com') +
                `/teacher/assessments?subject=science&school=${school.data?.name.split('/')[1]}`
              }
            >
              Assessments
              <Box display="inline-block" color="gray.300" float="right">
                <FontAwesomeIcon icon={faExternalLink} />
              </Box>
            </MenuItem>
          </>
        )}
        <MenuDivider />
        <MenuItem icon={MoreMenuIcon(faList)} as={Link} to="/packages">
          Student Demo
        </MenuItem>
      </TeacherContentMoreMenu>
    </Box>
  );

  if (navigationHidden) {
    return routes;
  }

  return (
    <>
      <WhatsNewPromotedDialog />
      <RolePrompt />
      <Box height="100%" width="100%" display="flex" position="absolute">
        {!sidebarHidden && sidebar}
        <Box flex={1} height="100%" position="relative" userSelect="text" overflow="hidden">
          {routes}
        </Box>
      </Box>
    </>
  );
};

export const TeacherOnlyContexts = ({ children }: PropsWithChildren) => {
  const { isTeacher } = useUserType();
  if (!isTeacher) {
    return children;
  }
  return (
    <IntercomContext>
      <TrainingContext>
        <SuspenseStaffContextProvider>{children}</SuspenseStaffContextProvider>
      </TrainingContext>
    </IntercomContext>
  );
};

const MoreMenuIcon = (icon: IconDefinition) => (
  <Text color="teal.600">
    <FontAwesomeIcon icon={icon} fixedWidth={true} />
  </Text>
);

export default TeacherContent;
