import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import {
  BonusAward,
  BonusAward_Reason,
  XpAward,
  XpAward_Reason,
} from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { useUpdateUserXpState } from 'api/xp';
import Popper from 'app/images/icon-popper.svg';
import { CurrentUserDisplayName } from 'components/Names';
import { PageContainer } from 'components/PageContainer';
import { xpTaskAnnotation } from 'components/xp/utils';
import { useXpContext } from 'components/xp/XpManager/context';
import { motion, useAnimationControls } from 'framer-motion';
import { useEffect, useMemo, useRef } from 'react';
import { useCountUp } from 'react-countup';
import { useKeyPress } from 'utils/hooks/keypress';

export const HomeworkComplete = ({
  pkg,
  onFinish,
}: {
  pkg?: Package;
  onFinish: (replace?: boolean) => void;
}) => {
  const { getAwardsToShow, markAwardsAsShown, showLevelUp } = useXpContext();

  // get controls for framer motion
  const framerControls = useAnimationControls();

  useKeyPress({ Enter: () => onFinish() });

  // get bonus xp awards
  const awards = useMemo(() => getAwardsToShow(XpAward_Reason.BONUS_XP), [getAwardsToShow]);

  // update the userXpState query to include the most recent xpState from the awards
  const updateUserXpState = useUpdateUserXpState();
  useEffect(() => {
    updateUserXpState(awards);
  }, [awards, updateUserXpState]);

  if (!pkg || awards.length === 0) {
    onFinish(true);
  }

  const packageXp = pkg?.contents?.tasks.reduce((acc, task) => {
    const xp = parseInt(task.annotations[xpTaskAnnotation]);
    return acc + (isNaN(xp) ? 0 : xp);
  }, 0);

  const bonusAwards = awards.reduce(
    (acc, award) => {
      // get reason for additional data
      const bonusAwardData = getBonusAwardData(award);
      if (bonusAwardData) {
        acc.total += bonusAwardData.bonusXpAwarded;
        acc.awards.push(bonusAwardData);
      }
      return acc;
    },
    { total: 0, awards: [] as BonusAward[] },
  );

  const countUpEndXp = (packageXp || 0) + bonusAwards.total;
  // countup gubbins
  const countupRef = useRef<HTMLDivElement>(null);
  const { start: startCountup } = useCountUp({
    ref: countupRef,
    start: packageXp,
    end: countUpEndXp,
    duration: animationDuration,
    delay: countDelay,
    easingFn: (t: number, b: number, c: number, d: number) =>
      Math.sin(((t / d) * Math.PI) / 2) * c + b,
  });

  // start the count up once the level up screen is not being shown
  useEffect(() => {
    if (!showLevelUp) {
      startCountup();
      framerControls.start('show');
    } else {
      framerControls.start('initial');
    }
  }, [framerControls, showLevelUp, startCountup]);

  return (
    <PageContainer>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        h="auto"
        w="100%"
        p={5}
        textAlign="center"
        id="1"
      >
        <Heading color="green.500" size="lg" fontWeight="bold">
          Homework Complete
        </Heading>
        <Text fontSize="xl">
          Well done, <CurrentUserDisplayName givenOnly />!
        </Text>
        <motion.div variants={popper} initial={'initial'} animate={framerControls}>
          <Image height={[100, 150, 200]} m={5} src={Popper} />
        </motion.div>
        <Text fontSize="xl">
          This homework you earned:{' '}
          <Text color="green.500" fontWeight="bold" as="span">
            <Box as="span" position="relative" whiteSpace="nowrap">
              {/* hidden div with the max size of the number (all 8's), so the size of the parent doesn't change as
                    we count up */}
              <Text opacity={0} as="span" whiteSpace="nowrap">
                {parseInt(countUpEndXp.toString().replaceAll(/./g, '8')).toLocaleString('en-GB')}
              </Text>
              <Text
                opacity={1}
                position="absolute"
                left={0}
                as="span"
                ref={countupRef}
                whiteSpace="nowrap"
              >
                {packageXp}
              </Text>
              XP
            </Box>
          </Text>
        </Text>
        <motion.div variants={container} initial="initial" animate={framerControls}>
          {bonusAwards.awards.map((bonusAward, i) => {
            // get reason for additional data
            const displayReason = getDisplayReason(bonusAward.bonusReason);
            if (!bonusAward || !displayReason) {
              return null;
            }
            return (
              <motion.div key={i} variants={rewardsItem}>
                <Text
                  backgroundColor="green.100"
                  fontSize="xl"
                  py={2}
                  px={3}
                  m={2}
                  mb={4}
                  borderRadius="md"
                >
                  <Text as="span" color="green.500" fontWeight="bold">
                    +{bonusAward.bonusXpAwarded} XP
                  </Text>{' '}
                  bonus for {displayReason}
                </Text>
              </motion.div>
            );
          })}
        </motion.div>
        <Button
          onClick={() => {
            onFinish();
            markAwardsAsShown(XpAward_Reason.BONUS_XP);
          }}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          colorScheme="buttonTeal"
          size={['sm', 'md']}
        >
          Next
        </Button>
      </Flex>
    </PageContainer>
  );
};

// getBonusAwardData returns the BonusAward data from the first bonusAward additionalData on the given XpAward
const getBonusAwardData = (award: XpAward): BonusAward | undefined => {
  for (const data of award.additionalData) {
    if (data.data.oneofKind === 'bonusAward') {
      return data.data.bonusAward;
    }
  }
  return undefined;
};

const getDisplayReason = (reason: BonusAward_Reason | undefined): string | undefined => {
  switch (reason) {
    case BonusAward_Reason.PACKAGE_COMPLETE:
      return 'completing your homework';
    case BonusAward_Reason.BONUS_REASON_UNKNOWN:
    default:
      return undefined;
  }
};

const animationDuration = 1;
const delay = 0.5;
const countDelay = delay + 0.5;

const rewardsItem = {
  initial: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: { duration: animationDuration },
  },
};

const container = {
  initial: {},
  show: {
    transition: {
      staggerChildren: animationDuration,
      delayChildren: delay,
    },
  },
};

const popper = {
  initial: { scale: 0.8 },
  show: {
    scale: 1,
    transition: { duration: 0.7, type: 'spring', damping: 4 },
  },
};
