import classNames from 'classnames';
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import styles from '../question/SparxQuestion.module.css';

interface StackProps {
  size?: 'small' | 'normal';
  dir?: 'horizontal' | 'vertical';
  className?: string;
  wrapChildren?: boolean;
  dataTag?: string;
}

export const Stack = forwardRef(
  (
    {
      children,
      size = 'normal',
      dir = 'horizontal',
      className,
      wrapChildren,
      dataTag,
    }: PropsWithChildren<StackProps>,
    forwardedRef: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={forwardedRef}
        className={classNames(
          {
            [styles.StackNormal]: size === 'normal',
            [styles.StackSmall]: size === 'small',
            [styles.StackHorizontal]: dir === 'horizontal',
            [styles.StackHorizontalRow]: dir === 'horizontal' && !wrapChildren,
            [styles.StackVertical]: dir === 'vertical',
            [styles.StackVerticalRow]: dir === 'vertical' && !wrapChildren,
            [styles.StackWrap]: wrapChildren,
          },
          className,
        )}
        data-stack={dataTag}
      >
        {children}
      </div>
    );
  },
);

Stack.displayName = 'Stack';
