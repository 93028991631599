import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { faCheck, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  HomeworkLength,
  StudentSettings,
} from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { AnnotationKeys } from '@sparx/schoolutils';
import { useSchool } from 'api/school';
import { useUnlinkStudentSSO } from 'api/schoolactions';
import { useUpdateStudentSettings } from 'api/scienceschool';
import { useUserType } from 'api/sessions';
import { getUpdateMask, translateErrorMessage } from 'api/utils';
import {
  EditableField,
  HeaderField,
  PageSubmitFooter,
  ReadonlyField,
  SubHeaderField,
  useFormId,
} from 'components/form/FormFields';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { unstable_usePrompt } from 'react-router-dom';
import {
  courseOptionNames,
  courseSelectionOptions,
  courseTierOptionNames,
  courseTierOptions,
  getOptionList,
  homeworkTypeNames,
  homeworkTypeOptions,
  initialLevelOptions,
  levelOptionNames,
  levelOptionNamesWithAutomatic,
  levelOptions,
} from 'views/students/StudentListView';
import { StudentPasswordReset } from 'views/students/StudentPasswordReset';
import { useStudentViewContext } from 'views/students/StudentView';

export const homeworkLevelTooltip =
  'This controls the difficulty and volume of work a student receives.';
export const initialHomeworkLevelTooltip = (
  <>
    This controls the <strong>Homework level</strong> for students who are new to Sparx.
  </>
);

export const StudentDetailView = () => {
  const { student, classGroup, regGroup, backLink } = useStudentViewContext();
  const { isSparxStaff } = useUserType();

  const { data: school } = useSchool({ suspense: true });

  const sparxLoginDisabled =
    school?.annotations[AnnotationKeys.LoginMethodsStudentsDisableSparx] === 'true';
  const studentSSOProvider = school?.annotations[AnnotationKeys.LoginMethodsStudentsSSOProvider];
  const studentSSOEnabled = !!studentSSOProvider && studentSSOProvider !== 'none';

  const showSSOUnlink =
    studentSSOEnabled &&
    !!student.login?.emailAddress &&
    // Only show the unlink button if the student has logged in at least once (we check both science & maths last login)
    (student?.scienceState?.lastLoginTimestamp || student?.login.lastLoginAt || isSparxStaff);

  const {
    handleSubmit,
    register,
    formState: { isDirty, dirtyFields },
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: student?.scienceSettings,
  });

  const editingLevel = watch('level');
  const editingLevelOverride = watch('levelOverride');

  const toast = useToast();
  const updateStudentSettings = useUpdateStudentSettings();

  unstable_usePrompt({
    message: 'You have unsaved changes. Are you sure you want to leave?',
    when: isDirty,
  });

  const formID = useFormId();

  const onSubmit = (settings: StudentSettings) =>
    updateStudentSettings
      .mutateAsync({
        students: [
          {
            ...settings,
            name: `students/${student.studentId}`,
          },
        ],
        paths: getUpdateMask(StudentSettings, dirtyFields),
      })
      .then(data => {
        toast({
          title: 'Student updated',
          status: 'success',
          position: 'bottom-left',
          duration: 5000,
        });
        reset(data.updatedSettings[0]);
      })
      .catch(err => {
        toast({
          title: 'Error updating student',
          description: translateErrorMessage(err),
          status: 'error',
          position: 'bottom-left',
          duration: 5000,
        });
      });

  return (
    <Box id={formID} as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box backgroundColor="white" p={8} width="100%">
        <Grid gap={4} templateColumns="repeat(2, 1fr)">
          <GridItem colSpan={2}>
            <Alert status="info" borderRadius="md">
              <AlertIcon />
              <Text>
                Some student data can&apos;t be changed within Sparx as it&apos;s managed by your
                school&apos;s MIS. If you need to make changes to this data, please speak to your
                school&apos;s MIS administrator.
              </Text>
            </Alert>
          </GridItem>
          <ReadonlyField name="First name" value={student.givenName} sentryMask />
          <ReadonlyField name="Surname" value={student.familyName} sentryMask />
          {studentSSOEnabled && (
            <ReadonlyField
              name="Email address"
              value={student.login?.emailAddress || ''}
              sentryMask
            />
          )}
          <ReadonlyField
            name="Birthday"
            value={
              student.dateOfBirth
                ? format(Timestamp.toDate(student.dateOfBirth), 'dd MMM yyyy')
                : 'Unknown'
            }
            sentryMask
          />
          <HeaderField name="Class details" />
          {!classGroup && (
            <GridItem colSpan={2}>
              <Alert status="warning" borderRadius="md">
                <AlertIcon />
                <Text>This student is not currently in a science class.</Text>
              </Alert>
            </GridItem>
          )}
          <ReadonlyField name="Class" value={classGroup?.displayName || 'No class'} />
          <ReadonlyField name="Registration group" value={regGroup?.displayName || 'No class'} />
          <HeaderField name="Sparx Science settings" />
          <GridItem colSpan={2}>
            <EditableField
              name="Set homework for this student?"
              tooltip={
                <>
                  Half-length will give students half as much as the rest of the class.
                  <br />
                  If homework is off, students will still have access to Independent Learning.
                </>
              }
            >
              <Controller
                control={control}
                name="homeworkLength"
                render={({ field: { onChange, value, ref } }) => (
                  <RadioGroup
                    value={value.toString()}
                    onChange={e => onChange(parseInt(e))}
                    ref={ref}
                  >
                    <HStack spacing={8}>
                      {homeworkTypeOptions.map(option => (
                        <Radio value={option.toString()} key={option}>
                          {option === HomeworkLength.OFF
                            ? 'Homework off'
                            : homeworkTypeNames[option]}
                        </Radio>
                      ))}
                    </HStack>
                  </RadioGroup>
                )}
              />
            </EditableField>
          </GridItem>
          {student?.scienceSettings?.levelAutomatic || editingLevelOverride ? (
            <ReadonlyField
              name="Initial homework level"
              value="Not in use"
              tooltip={initialHomeworkLevelTooltip}
            >
              <Box px={2} pt={2} fontSize="sm" color="gray.700">
                {student?.scienceSettings?.levelAutomatic ? (
                  <Text>This setting is only used when the student is new to Sparx.</Text>
                ) : (
                  <Text>
                    Not in use because the <strong>Homework level</strong> has been manually set.
                  </Text>
                )}
              </Box>
            </ReadonlyField>
          ) : (
            <EditableField name="Initial homework level" tooltip={initialHomeworkLevelTooltip}>
              <Select {...register('level')}>
                {getOptionList(initialLevelOptions, levelOptionNames)}
              </Select>
              {editingLevelOverride && (
                <Box px={2} pt={2} fontSize="sm" color="gray.700">
                  <Text>
                    Not in use because the <strong>Homework level</strong> has been manually set.
                  </Text>
                </Box>
              )}
            </EditableField>
          )}
          <EditableField name="Homework level" tooltip={homeworkLevelTooltip}>
            <Select {...register('levelOverride')}>
              {getOptionList(
                levelOptions,
                levelOptionNamesWithAutomatic(
                  student?.scienceSettings?.levelAutomatic || editingLevel,
                ),
              )}
            </Select>

            {(student?.scienceSettings?.levelAutomatic || !editingLevelOverride) && (
              <Box px={2} pt={2} fontSize="sm" color="gray.700">
                {student?.scienceSettings?.levelAutomatic ? (
                  editingLevelOverride ? (
                    <Text>
                      If you switch to <strong>Automatic</strong>, Sparx will automatically adjust
                      the <strong>Homework level</strong> based on this student&apos;s activity.
                    </Text>
                  ) : (
                    <Text>
                      Sparx will deliver work at{' '}
                      <strong>Level {student.scienceSettings.levelAutomatic}</strong> based on their
                      recent activity.
                    </Text>
                  )
                ) : (
                  <Text>
                    Sparx is still learning about this student and is using the{' '}
                    <strong>Initial homework level</strong>.
                  </Text>
                )}
              </Box>
            )}
          </EditableField>

          <SubHeaderField
            name="GCSE topic settings"
            subText="These settings are only used when GCSE topics are assigned to the class"
          />
          <EditableField
            name="Set higher questions?"
            tooltip="If set to 'No' we will exclude questions only in the higher specification from the student's homework."
          >
            <Select {...register('tier', { valueAsNumber: true })}>
              {getOptionList(courseTierOptions, courseTierOptionNames)}
            </Select>
          </EditableField>
          <EditableField
            name="Set separate questions?"
            tooltip="If set to 'No' we will exclude questions only in the separate specification from the student's homework."
          >
            <Select {...register('course', { valueAsNumber: true })}>
              {getOptionList(courseSelectionOptions, courseOptionNames)}
            </Select>
          </EditableField>

          <HeaderField name="User account information" />
          {!sparxLoginDisabled && (
            <ReadonlyField
              name="Username"
              value={inSchoolUsername(student.login?.username || '-')}
              sentryMask
            />
          )}
          <EditableField name="Login status">
            <Flex h={10} alignItems="center">
              {student.scienceState?.lastLoginTimestamp ? (
                <>
                  <Text mr={2} color="green.400">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </Text>
                  Last login:{' '}
                  {format(
                    Timestamp.toDate(student.scienceState.lastLoginTimestamp),
                    'eee do MMMM h:mmaaa',
                  )}
                </>
              ) : (
                <>
                  <Text mr={2} color="red.400">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </Text>
                  Never logged in
                </>
              )}
            </Flex>
          </EditableField>
          {!sparxLoginDisabled && (
            <EditableField name="Password" span={showSSOUnlink ? 1 : 2}>
              {student.login?.passwordResetRequestedAt && !student.login?.passwordResetGrantedAt ? (
                <Alert status="warning" borderRadius="md">
                  <AlertIcon />
                  <Text flex={1}>Student has requested to reset their password</Text>
                  <Box my={-1} mr={-2}>
                    <StudentPasswordReset studentID={student.studentId} />
                  </Box>
                </Alert>
              ) : student.login?.passwordSetAt && !student.login?.passwordResetGrantedAt ? (
                <Box>
                  <StudentPasswordReset studentID={student.studentId} />
                </Box>
              ) : student.login?.passwordResetRequestedAt || student.login?.passwordSetAt ? (
                <Alert status="info" borderRadius="md">
                  <AlertIcon />
                  <Text>
                    Student is able to reset their password by visiting &apos;
                    <strong>Forgot login details</strong>&apos; from their login page.
                  </Text>
                </Alert>
              ) : (
                <Alert status="info" borderRadius="md">
                  <AlertIcon />
                  <Text>
                    Student can set their password by visiting &apos;<strong>New student?</strong>
                    &apos; from their login page
                    {studentSSOEnabled ? ', or they can log in using SSO.' : '.'}
                  </Text>
                </Alert>
              )}
            </EditableField>
          )}
          {showSSOUnlink && <UnlinkSSOField studentId={student.studentId} />}
        </Grid>
      </Box>
      <PageSubmitFooter
        formID={formID}
        isDirty={isDirty}
        isLoading={updateStudentSettings.isLoading}
        backLink={backLink}
      />
    </Box>
  );
};

const inSchoolUsername = (username: string) => username.slice(0, username.lastIndexOf('@'));

const UnlinkSSOField = ({ studentId }: { studentId: string }) => {
  const toast = useToast();
  const { mutate, isLoading, isSuccess } = useUnlinkStudentSSO();

  const onUnlink = async () => {
    mutate(
      { studentId },
      {
        onError: () => {
          toast({
            title: 'Error unlinking SSO account',
            status: 'error',
            duration: 5000,
            position: 'bottom-left',
          });
        },
      },
    );
  };

  return (
    <EditableField
      name="Unlink SSO account"
      tooltip="If the student is having trouble logging in to Sparx using their SSO account, you can try unlinking their SSO account from their Sparx account. We'll then re-link their SSO account on their next successful login."
    >
      {!isSuccess ? (
        <Button
          alignSelf="flex-start"
          colorScheme="red"
          variant="outline"
          onClick={onUnlink}
          isLoading={isLoading}
        >
          Unlink
        </Button>
      ) : (
        <Text
          color="green.500"
          alignSelf="flex-start"
          px={4}
          borderRadius="md"
          border="1px solid"
          height={10}
          display="flex"
          justifyContent="center"
          alignItems={'center'}
        >
          <Box as="span" mr={1}>
            <FontAwesomeIcon icon={faCheck} />
          </Box>
          Unlinked
        </Text>
      )}
    </EditableField>
  );
};
