import { isComplete } from '@sparx/packageactivity';
import { usePackage } from 'api/packages';
import { useBackLink } from 'app/BackLink';
import React, { useCallback, useMemo } from 'react';
import { useLessonDeliveryContext } from 'views/lessondelivery/LessonDeliveryView';
import { TaskDelivery } from 'views/task/TaskView';

interface LessonTaskDeliveryProps {
  lessonActivityID: string;
  packageID: string;
  taskID: string;
  page?: number | 'results';
  isForced?: boolean;
}

export const LessonTaskDelivery = ({
  lessonActivityID,
  packageID,
  taskID,
  page,
  isForced,
}: LessonTaskDeliveryProps) => {
  const ldc = useLessonDeliveryContext();

  const setTaskPage = (taskItem: number | 'results') =>
    ldc.navigate({ page: 'task', lessonActivityID, packageID, taskID, taskItem });

  const { data } = usePackage(packageID, { suspense: true });
  const taskName = `${data?.package?.name}/tasks/${taskID}`;
  const task = data?.package?.contents?.tasks.find(t => t.name === taskName);
  const lockedInTask = !isComplete(task?.state?.completion) && isForced;

  const onBack = useCallback(
    (back?: boolean) => {
      if (lockedInTask && !back) {
        // If not complete force to next available task in this activity
        ldc.navigate({ page: 'setto', activityId: lessonActivityID });
      } else {
        // If complete go back to landing
        ldc.navigate({ page: 'landing', lastActivityId: lessonActivityID });
      }
    },
    [ldc, lessonActivityID, lockedInTask],
  );

  const backLink = useMemo(() => ({ onClick: () => onBack(true) }), [onBack]);
  useBackLink(backLink);

  return (
    <TaskDelivery
      packageID={packageID}
      taskID={taskID}
      page={page}
      onSetPage={(t: number | 'results' | 'complete') => {
        if (t === 'complete') {
          return;
        }
        setTaskPage(t);
      }}
      onBack={onBack}
    />
  );
};
