import { Box, Button, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task, TaskItem } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { XpAward, XpAward_Reason } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { isComplete } from '@sparx/packageactivity';
import { ProgressBar } from '@sparx/sparx-design/components';
import { useTaskItemActivities } from 'api/packages';
import { useUpdateUserXpState } from 'api/xp';
import { Hide, useMediaQuery } from 'components/chakraExports';
import { LevelBadge } from 'components/xp/Badges/Badge';
import { getLevelProgress } from 'components/xp/utils';
import { useXpContext } from 'components/xp/XpManager/context';
import { formatDuration, intervalToDuration } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo } from 'react';
import { useKeyPress } from 'utils/hooks/keypress';
import { isTaskRapidFire } from 'utils/rapidfire';

interface ResultsProps {
  task: Task | undefined;
  onContinue?: () => void;
  onFinish?: () => void;
}

export const Results = ({ task, onContinue, onFinish }: ResultsProps) => {
  const complete = isComplete(task?.state?.completion);
  const completion = task?.state?.completion;
  const completeItems = (completion?.progress?.['C'] || 0) + (completion?.progress?.['SK'] || 0);
  const cftItems = completion?.progress?.['CFT'] || 0;
  const totalItems = completion?.size || 0;

  // get any xp rewards from the task
  const { getAwardsToShow, markAwardsAsShown, showLevelUp } = useXpContext();
  const awards = useMemo(() => getAwardsToShow(XpAward_Reason.TASK_COMPLETE), [getAwardsToShow]);

  const showAwards = awards.length > 0;

  // update the userXpState query to include the most recent xpState from the awards
  const updateUserXpState = useUpdateUserXpState();
  useEffect(() => {
    updateUserXpState(awards);
  }, [awards, updateUserXpState]);

  useKeyPress({ Enter: () => (!complete ? onContinue?.() : onFinish?.()) });

  const isRapidFire = isTaskRapidFire(task);

  let content = null;
  let button = null;
  if (!complete) {
    content = (
      <>
        <Heading size="lg">Keep going!</Heading>
        <Text color="gray.500" fontWeight="bold">
          {completeItems}/{totalItems} complete
        </Text>
      </>
    );
    button = (
      <Button
        size={['sm', 'md']}
        colorScheme="buttonTeal"
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={onContinue}
      >
        Keep going
      </Button>
    );
  } else {
    content = (
      <>
        <Hide breakpoint={`(max-height: ${showAwards ? 700 : 500}px)`}>
          <Box
            width={100}
            height={100}
            color="green.500"
            fontSize="5xl"
            display="flex"
            alignItems="center"
            justifyContent="center"
            background="gray.100"
            borderRadius="full"
            mb={4}
            flex="0 0 auto"
          >
            <FontAwesomeIcon icon={faCheck} />
          </Box>
        </Hide>
        <Heading size="lg">
          <Text color="green.500" as="span">
            Task complete!
          </Text>{' '}
          Well done.
        </Heading>
        {!isRapidFire ? (
          <Text color="gray.500" fontWeight="bold">
            You got {cftItems}/{totalItems} correct first time.
          </Text>
        ) : (
          <RapidFireResults taskItem={task?.contents?.taskItems[0]} />
        )}
        {showAwards && <XpCard animate={!showLevelUp} xpAwards={awards} />}
      </>
    );
    button = (
      <Button
        size={['sm', 'md']}
        colorScheme="buttonTeal"
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={() => {
          onFinish?.();
          markAwardsAsShown(XpAward_Reason.TASK_COMPLETE);
        }}
      >
        Finish
      </Button>
    );
  }

  const [smallHeight] = useMediaQuery('(max-height: 800px)');
  return (
    <VStack h="100%">
      <VStack spacing={2} overflow="auto" flex="1 1 auto" py={smallHeight ? 4 : 14} w="100%">
        {content}
      </VStack>
      <HStack flex="0 0 auto" w="100%" p={3} justifyContent="flex-end">
        {button}
      </HStack>
    </VStack>
  );
};

const XpCard = ({ xpAwards, animate }: { xpAwards: XpAward[]; animate: boolean }) => {
  const xpEarned = xpAwards.reduce((acc, award) => acc + award.xpAwarded, 0);
  // last award is the most recent
  const xpState = xpAwards[xpAwards.length - 1];
  const { level, levelCompletion, xpToNext } = getLevelProgress(
    xpState.xpStateAfterAward?.currentXp || 0,
  );
  // if the level completion is 0, we are at a level exactly, so show it as full rather than empty
  const lvlComp = levelCompletion || 1;
  return (
    <Flex backgroundColor="gray.100" p={5} borderRadius={'lg'} flexDir="column" mt={5} mb={5}>
      <Heading color="green.500" fontWeight="bold" size="lg">
        You earned +{xpEarned} XP!
      </Heading>
      <HStack pt={5}>
        <ProgressBar animateFill={animate} showHighlight percentComplete={lvlComp * 100} />
        <LevelBadge
          level={level + 1}
          width={100}
          filter={levelCompletion < 1 ? 'grayscale(1)' : ''}
          opacity={levelCompletion < 1 ? 0.6 : 1}
        />
      </HStack>
      {xpToNext <= 100 && (
        <Text pt={5} color={'blue.800'} textAlign="center" fontWeight={'bold'} fontSize={'lg'}>
          {xpToNext} XP more to reach Level {level + 1}
        </Text>
      )}
    </Flex>
  );
};

const RapidFireResults = ({ taskItem }: { taskItem?: TaskItem }) => {
  const { data: activities, isSuccess } = useTaskItemActivities(taskItem?.name || '');

  if (!taskItem || !taskItem.state) {
    return null;
  }

  let accuracy =
    (taskItem.state.correctActivities * 100) /
    (taskItem.state.incorrectActivities + taskItem.state.correctActivities);

  // Round correct up or down to nearest integer if it's close to 0 or 100
  if (accuracy > 0 && accuracy < 1) accuracy = Math.ceil(accuracy);
  else if (accuracy > 99 && accuracy < 100) accuracy = Math.floor(accuracy);
  else accuracy = Math.round(accuracy);

  const totalTime = (activities || []).reduce((acc, activity) => {
    return acc + activity.questionTimeSeconds + activity.supportTimeSeconds;
  }, 0);

  return (
    <>
      <Text color="gray.500" fontWeight="bold">
        You were {accuracy}% accurate!
      </Text>
      <AnimatePresence>
        {isSuccess ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.4,
              delay: 0.3,
              ease: [0, 0.71, 0.2, 1.5],
            }}
          >
            <Text color="gray.500" fontWeight="bold">
              You completed it in{' '}
              {formatDuration(intervalToDuration({ start: 0, end: totalTime * 1000 }), {
                format: ['minutes', 'seconds'],
              })}
              !
            </Text>
          </motion.div>
        ) : (
          <Text color="gray.500" fontWeight="bold">
            {/* This is a placeholder to stop the page from shifting when the activities have loaded */}
            &nbsp;
          </Text>
        )}
      </AnimatePresence>
    </>
  );
};
