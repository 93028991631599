import { useLocation } from 'react-router-dom';

const uuidReString = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
const lqdPathRe = new RegExp(`/packages/${uuidReString}/tasks/${uuidReString}/`, 'i');

export const useIsLqd = () => {
  const { pathname } = useLocation();
  return lqdPathRe.test(pathname);
};

// the server uses this, any change should also be made in science/server/pkg/pkgsrv/anns/annotations.go
export const xpTaskAnnotation = 'xp/value';

// the server uses this, any change should also be made in science/server/pkg/pkgsrv/xp/xp.go
export const xpPerLevel = 1000;

export const getLevelProgress = (xp: number) => {
  return {
    level: Math.floor(xp / xpPerLevel),
    levelCompletion: (xp % xpPerLevel) / xpPerLevel,
    xpToNext: xpPerLevel - (xp % xpPerLevel),
  };
};
