import { Box, Center } from '@chakra-ui/react';
import { DomainCheck } from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useIntercom } from '@sparx/intercom';
import { getClientAnalyticsURL } from 'api';
import { useIsInLesson } from 'api/lessons';
import { useSession } from 'api/sessions';
import { sentryCreateBrowserRouter } from 'app/analytics';
import { useNavigationStyle } from 'app/BackLink';
import { ClassSelectionProvider } from 'app/ClassSelection';
import { Header } from 'app/Header';
import { AnalyticsShimProvider } from 'components/AnalyticsShimProvider';
import { ClientEventProvider, useClientEvent } from 'components/ClientEventProvider';
import { NotFound } from 'components/errorpages/NotFound';
import { LargeLoading } from 'components/loading/LargeLoading';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { XpManager } from 'components/xp/XpManager/XpMananger';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AssessmentsView } from 'views/assessments/Assessments';
import { IndependentLearningView } from 'views/independentlearning/IndependentLearning';
import { LandingView } from 'views/landing/LandingView';
import { LessonDeliveryView } from 'views/lessondelivery/LessonDeliveryView';
import { PackageView } from 'views/package/PackageView';
import { PackageListView } from 'views/packagelist/PackageListView';
import { StudentSettingsView } from 'views/settings';
import { OverlayProvider } from 'views/settings/Overlay';
import { TetheringView } from 'views/tethering/TetheringView';
import { XpView } from 'views/xp';

import { WithPreloadErrorHandler } from './PreloadErrorHandler';
import { useConfigureStatusEmbed } from './StatusEmbed';
import { TeacherOnlyContexts } from './TeacherContent';

// Lazy loading for bundle splitting
const TeacherContent = React.lazy(() => import('./TeacherContent'));
const TaskView = React.lazy(() => import('views/task/TaskView'));

const sus = (element: React.ReactNode) => <SuspenseRoute>{element}</SuspenseRoute>;

export const Content = () => {
  const navigationStyle = useNavigationStyle();
  const hidden = navigationStyle === 'hidden';

  const { data: user, isLoading } = useSession({ suspense: true });
  useConfigureStatusEmbed();

  // Use intercom widget
  const { update: updateIntercom } = useIntercom();

  // Page events
  const location = useLocation();
  const { sendEvent } = useClientEvent();
  const [lastPathKey, setLastPathKey] = useState('');
  const [previousPath, setPreviousPath] = useState<string | undefined>(undefined);
  useEffect(() => {
    const pathname = location.pathname + location.search;
    if (location.key !== lastPathKey) {
      setLastPathKey(location.key);
      sendEvent(
        { action: 'pageview', category: 'navigate' },
        { ...(previousPath && { previousPath }), path: pathname },
      );
      setPreviousPath(pathname);

      // Send intercom update if available
      updateIntercom();
    }
  }, [lastPathKey, setLastPathKey, location, sendEvent, updateIntercom, previousPath]);

  // Fix into lessons page if in lessons mode
  const isInLesson = useIsInLesson();
  if (isInLesson && location.pathname !== '/lesson') {
    return <Navigate to="/lesson" />;
  } else if (!isInLesson && location.pathname === '/lesson') {
    return <Navigate to="/" />;
  }

  const globalLoading = !user || isLoading;
  return (
    <>
      <AnimatePresence>
        {globalLoading && (
          <Center
            as={motion.div}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            bg="pageBackground"
            position="fixed"
            inset="0 0 0 0"
            zIndex={100}
          >
            <LargeLoading color="teal.100" />
          </Center>
        )}
      </AnimatePresence>
      {!globalLoading && (
        <>
          {user.schoolId && (
            <DomainCheck
              schoolId={`schools/${user.schoolId}`}
              product={Product.SPARX_SCIENCE}
              userType={user.userType}
            />
          )}
          <ClassSelectionProvider>
            <Header />
            {hidden ? (
              <Outlet />
            ) : (
              <Box flex={1} bg="pageBackground" position="relative">
                <Outlet />
              </Box>
            )}
            {/*<ServiceWorker />*/}
          </ClassSelectionProvider>
        </>
      )}
    </>
  );
};

const ContentWithClientEventsProvider = () => (
  <ClientEventProvider pushURL={getClientAnalyticsURL()}>
    <AnalyticsShimProvider>
      <WithPreloadErrorHandler>
        <SuspenseRoute verticalCenter={true}>
          <OverlayProvider>
            <TeacherOnlyContexts>
              <XpManager>
                <Content />
              </XpManager>
            </TeacherOnlyContexts>
          </OverlayProvider>
        </SuspenseRoute>
      </WithPreloadErrorHandler>
    </AnalyticsShimProvider>
  </ClientEventProvider>
);

export const router = sentryCreateBrowserRouter([
  {
    element: <ContentWithClientEventsProvider />,
    children: [
      { path: '/', element: <LandingView /> },
      { path: '/packages', element: sus(<PackageListView />) },
      { path: '/packages/:packageID', element: sus(<PackageView />) },
      { path: '/packages/:packageID/tasks/:taskID/:taskItem?', element: sus(<TaskView />) },
      { path: '/independentlearning', element: sus(<IndependentLearningView />) },
      { path: '/assessments', element: sus(<AssessmentsView />) },
      { path: '/teacher/*', element: sus(<TeacherContent />) },
      { path: '/tethering', element: <TetheringView /> },
      { path: '/lesson', element: <LessonDeliveryView /> },
      { path: '/user-settings', element: sus(<StudentSettingsView />) },
      { path: '/rewards', element: sus(<XpView />) },
      { path: '*', element: <NotFound /> },
    ],
  },
]);
