import { XpAward } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { xpClient } from 'api';
import { useIsLqd } from 'components/xp/utils';
import { useCallback } from 'react';

import { FeatureFlag, useBooleanFlag } from './sessions';

const userXpQueryKey = ['userXpState'];

// useUserXpState is a query which fetches the current user's XP state.
// We disable the query when in lqd, and update it manually when xp is awarded.
export const useUserXpState = () => {
  const xpEnabled = useBooleanFlag(FeatureFlag.Xp);
  const isLqd = useIsLqd();
  return useQuery({
    queryKey: userXpQueryKey,
    queryFn: () => xpClient.getCurrentUserXpState({}).response,
    suspense: true,
    select: data => data.xpState,
    enabled: xpEnabled && !isLqd,
  });
};

// useUpdateUserXpState returns a function to update the useXpStateQuery to reflect the most recent XP state after the
// given awards.
export const useUpdateUserXpState = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (awards: XpAward[]) => {
      const state = awards[awards.length - 1]?.xpStateAfterAward;
      if (state) {
        queryClient.setQueryData(userXpQueryKey, { xpState: state });
      }
    },
    [queryClient],
  );
};
