import { fromUnixTime, getUnixTime, isBefore, sub } from 'date-fns';

const LOCAL_STORAGE_KEY = 'last-reload-attempt';

export const attemptReload = () => {
  const lastAttemptAt = getLastAttemptAt();
  if (!lastAttemptAt || isLastAttemptBeforeThreshold(lastAttemptAt)) {
    setLastAttemptNow();
    window.location.reload();
  }
};

const getLastAttemptAt = (): Date | null => {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    return value ? fromUnixTime(parseInt(value, 10)) : null;
  } catch (e) {
    // If there is an issue reading the value then return now, so we don't get stuck in a reload loop
    return new Date();
  }
};

const setLastAttemptNow = () => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, getUnixTime(new Date()).toString());
  } catch (e) {
    console.error('Failed to set last reload attempt', e);
  }
};

const isLastAttemptBeforeThreshold = (lastAttemptAt: Date) =>
  isBefore(lastAttemptAt, sub(new Date(), { seconds: 10 }));
