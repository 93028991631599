import { Box, Text } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'components/chakraExports';
import queryString from 'query-string';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface SidebarButtonProps {
  title: string;
  icon: IconDefinition;
  activeIcon?: IconDefinition;
  to: string;
  preserveSearch?: string[];
}

export const SidebarButton = ({
  title,
  icon,
  activeIcon,
  to,
  preserveSearch,
}: SidebarButtonProps) => {
  const location = useLocation();
  const active = location.pathname === to;
  const [isLargeHeight] = useMediaQuery('(min-height: 700px)');

  const linkTo = useMemo(() => {
    const query = queryString.parse(location.search);
    const newQuery: queryString.ParsedQuery<string> = {};
    for (const key of preserveSearch || []) {
      if (query[key]) {
        newQuery[key] = query[key];
      }
    }
    const extra = queryString.stringify(newQuery);
    return to + (extra ? `?${extra}` : '');
  }, [to, location, preserveSearch]);

  return (
    <Box
      mb={2}
      _hover={{
        backgroundColor: 'gray.100',
      }}
      backgroundColor={active ? 'pageBackground' : 'white'}
      borderRadius="md"
      py={isLargeHeight ? 3 : 1}
      as={Link}
      to={linkTo}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
    >
      <Text fontSize="2xl" textColor={active ? 'teal.600' : 'gray.400'}>
        <FontAwesomeIcon icon={active && activeIcon ? activeIcon : icon} />
      </Text>
      <Text fontSize="sm" textColor="gray.900" fontWeight="bold">
        {title}
      </Text>
    </Box>
  );
};
