// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/xp/v1/xp.proto" (package "sparx.science.xp.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.xp.v1.BatchGetStudentXpStateRequest
 */
export interface BatchGetStudentXpStateRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: repeated string student_names = 2;
   */
  studentNames: string[];
}
/**
 * @generated from protobuf message sparx.science.xp.v1.BatchGetStudentXpStateResponse
 */
export interface BatchGetStudentXpStateResponse {
  /**
   * @generated from protobuf field: map<string, sparx.science.xp.v1.XpState> xp_state_map = 1;
   */
  xpStateMap: {
    [key: string]: XpState;
  };
}
/**
 * @generated from protobuf message sparx.science.xp.v1.BatchGetILTargetXpRequest
 */
export interface BatchGetILTargetXpRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf oneof: query
   */
  query:
    | {
        oneofKind: 'assignmentName';
        /**
         * @generated from protobuf field: string assignment_name = 2;
         */
        assignmentName: string;
      }
    | {
        oneofKind: 'studentName';
        /**
         * @generated from protobuf field: string student_name = 3;
         */
        studentName: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.xp.v1.BatchGetILTargetXpResponse
 */
export interface BatchGetILTargetXpResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.xp.v1.ILTargetState il_target_xp_states = 1;
   */
  ilTargetXpStates: ILTargetState[];
}
/**
 * @generated from protobuf message sparx.science.xp.v1.ListCurrentUserILTargetXpRequest
 */
export interface ListCurrentUserILTargetXpRequest {}
/**
 * @generated from protobuf message sparx.science.xp.v1.ListCurrentUserILTargetXpResponse
 */
export interface ListCurrentUserILTargetXpResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.xp.v1.ILTargetState il_target_xp_state = 1;
   */
  ilTargetXpState: ILTargetState[];
}
/**
 * @generated from protobuf message sparx.science.xp.v1.GetCurrentUserXpStateRequest
 */
export interface GetCurrentUserXpStateRequest {}
/**
 * @generated from protobuf message sparx.science.xp.v1.GetCurrentUserXpStateResponse
 */
export interface GetCurrentUserXpStateResponse {
  /**
   * @generated from protobuf field: sparx.science.xp.v1.XpState xp_state = 1;
   */
  xpState?: XpState;
}
/**
 * XpAward is an award of Xp to a student
 *
 * @generated from protobuf message sparx.science.xp.v1.XpAward
 */
export interface XpAward {
  /**
   * @generated from protobuf field: sparx.science.xp.v1.XpAward.Reason reason = 1;
   */
  reason: XpAward_Reason;
  /**
   * @generated from protobuf field: repeated sparx.science.xp.v1.AdditionalData additional_data = 2;
   */
  additionalData: AdditionalData[];
  /**
   * @generated from protobuf field: int32 xp_awarded = 3;
   */
  xpAwarded: number;
  /**
   * @generated from protobuf field: sparx.science.xp.v1.XpState xp_state_after_award = 4;
   */
  xpStateAfterAward?: XpState;
}
/**
 * @generated from protobuf enum sparx.science.xp.v1.XpAward.Reason
 */
export enum XpAward_Reason {
  /**
   * @generated from protobuf enum value: AWARD_REASON_UNKNOWN = 0;
   */
  AWARD_REASON_UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: TASK_COMPLETE = 1;
   */
  TASK_COMPLETE = 1,
  /**
   * @generated from protobuf enum value: BONUS_XP = 2;
   */
  BONUS_XP = 2,
}
/**
 * Additional data that can be attached to an XpAward which provides context about the award, but isnt part of the
 * students XpState
 *
 * @generated from protobuf message sparx.science.xp.v1.AdditionalData
 */
export interface AdditionalData {
  /**
   * @generated from protobuf oneof: data
   */
  data:
    | {
        oneofKind: 'ilTarget';
        /**
         * @generated from protobuf field: sparx.science.xp.v1.ILTarget il_target = 1;
         */
        ilTarget: ILTarget;
      }
    | {
        oneofKind: 'bonusAward';
        /**
         * @generated from protobuf field: sparx.science.xp.v1.BonusAward bonus_award = 2;
         */
        bonusAward: BonusAward;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.xp.v1.BonusAward
 */
export interface BonusAward {
  /**
   * @generated from protobuf field: sparx.science.xp.v1.BonusAward.Reason bonus_reason = 1;
   */
  bonusReason: BonusAward_Reason;
  /**
   * @generated from protobuf field: int32 bonus_xp_awarded = 2;
   */
  bonusXpAwarded: number;
}
/**
 * @generated from protobuf enum sparx.science.xp.v1.BonusAward.Reason
 */
export enum BonusAward_Reason {
  /**
   * @generated from protobuf enum value: BONUS_REASON_UNKNOWN = 0;
   */
  BONUS_REASON_UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: PACKAGE_COMPLETE = 1;
   */
  PACKAGE_COMPLETE = 1,
}
/**
 * @generated from protobuf message sparx.science.xp.v1.ILTarget
 */
export interface ILTarget {
  /**
   * @generated from protobuf field: sparx.science.xp.v1.ILTargetState il_target_state_after_award = 1;
   */
  ilTargetStateAfterAward?: ILTargetState;
}
/**
 * XpState is a high level state of a students Xp.
 * In the future it might also hold levels and other Xp related data.
 * More detailed Xp data should be fetched from aggserver
 *
 * @generated from protobuf message sparx.science.xp.v1.XpState
 */
export interface XpState {
  /**
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
  /**
   * @generated from protobuf field: int32 current_xp = 2;
   */
  currentXp: number;
  /**
   * @generated from protobuf field: int32 level = 3;
   */
  level: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_leveled_timestamp = 4;
   */
  lastLeveledTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.xp.v1.ILTargetState
 */
export interface ILTargetState {
  /**
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
  /**
   * @generated from protobuf field: string assignment_name = 2;
   */
  assignmentName: string;
  /**
   * @generated from protobuf field: int32 target_xp = 3;
   */
  targetXp: number;
  /**
   * @generated from protobuf field: int32 xp_earned = 4;
   */
  xpEarned: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 5;
   */
  startTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_timestamp = 6;
   */
  endTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp cancelled_time = 7;
   */
  cancelledTime?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetStudentXpStateRequest$Type extends MessageType<BatchGetStudentXpStateRequest> {
  constructor() {
    super('sparx.science.xp.v1.BatchGetStudentXpStateRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.BatchGetStudentXpStateRequest
 */
export const BatchGetStudentXpStateRequest =
  new BatchGetStudentXpStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetStudentXpStateResponse$Type extends MessageType<BatchGetStudentXpStateResponse> {
  constructor() {
    super('sparx.science.xp.v1.BatchGetStudentXpStateResponse', [
      {
        no: 1,
        name: 'xp_state_map',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => XpState },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.BatchGetStudentXpStateResponse
 */
export const BatchGetStudentXpStateResponse =
  new BatchGetStudentXpStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetILTargetXpRequest$Type extends MessageType<BatchGetILTargetXpRequest> {
  constructor() {
    super('sparx.science.xp.v1.BatchGetILTargetXpRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assignment_name',
        kind: 'scalar',
        oneof: 'query',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_name',
        kind: 'scalar',
        oneof: 'query',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.BatchGetILTargetXpRequest
 */
export const BatchGetILTargetXpRequest = new BatchGetILTargetXpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetILTargetXpResponse$Type extends MessageType<BatchGetILTargetXpResponse> {
  constructor() {
    super('sparx.science.xp.v1.BatchGetILTargetXpResponse', [
      {
        no: 1,
        name: 'il_target_xp_states',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ILTargetState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.BatchGetILTargetXpResponse
 */
export const BatchGetILTargetXpResponse = new BatchGetILTargetXpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurrentUserILTargetXpRequest$Type extends MessageType<ListCurrentUserILTargetXpRequest> {
  constructor() {
    super('sparx.science.xp.v1.ListCurrentUserILTargetXpRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.ListCurrentUserILTargetXpRequest
 */
export const ListCurrentUserILTargetXpRequest =
  new ListCurrentUserILTargetXpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurrentUserILTargetXpResponse$Type extends MessageType<ListCurrentUserILTargetXpResponse> {
  constructor() {
    super('sparx.science.xp.v1.ListCurrentUserILTargetXpResponse', [
      {
        no: 1,
        name: 'il_target_xp_state',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ILTargetState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.ListCurrentUserILTargetXpResponse
 */
export const ListCurrentUserILTargetXpResponse =
  new ListCurrentUserILTargetXpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUserXpStateRequest$Type extends MessageType<GetCurrentUserXpStateRequest> {
  constructor() {
    super('sparx.science.xp.v1.GetCurrentUserXpStateRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.GetCurrentUserXpStateRequest
 */
export const GetCurrentUserXpStateRequest =
  new GetCurrentUserXpStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUserXpStateResponse$Type extends MessageType<GetCurrentUserXpStateResponse> {
  constructor() {
    super('sparx.science.xp.v1.GetCurrentUserXpStateResponse', [
      { no: 1, name: 'xp_state', kind: 'message', T: () => XpState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.GetCurrentUserXpStateResponse
 */
export const GetCurrentUserXpStateResponse =
  new GetCurrentUserXpStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class XpAward$Type extends MessageType<XpAward> {
  constructor() {
    super('sparx.science.xp.v1.XpAward', [
      {
        no: 1,
        name: 'reason',
        kind: 'enum',
        T: () => ['sparx.science.xp.v1.XpAward.Reason', XpAward_Reason],
      },
      {
        no: 2,
        name: 'additional_data',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AdditionalData,
      },
      { no: 3, name: 'xp_awarded', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'xp_state_after_award',
        kind: 'message',
        T: () => XpState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.XpAward
 */
export const XpAward = new XpAward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdditionalData$Type extends MessageType<AdditionalData> {
  constructor() {
    super('sparx.science.xp.v1.AdditionalData', [
      {
        no: 1,
        name: 'il_target',
        kind: 'message',
        oneof: 'data',
        T: () => ILTarget,
      },
      {
        no: 2,
        name: 'bonus_award',
        kind: 'message',
        oneof: 'data',
        T: () => BonusAward,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.AdditionalData
 */
export const AdditionalData = new AdditionalData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusAward$Type extends MessageType<BonusAward> {
  constructor() {
    super('sparx.science.xp.v1.BonusAward', [
      {
        no: 1,
        name: 'bonus_reason',
        kind: 'enum',
        T: () => ['sparx.science.xp.v1.BonusAward.Reason', BonusAward_Reason],
      },
      {
        no: 2,
        name: 'bonus_xp_awarded',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.BonusAward
 */
export const BonusAward = new BonusAward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ILTarget$Type extends MessageType<ILTarget> {
  constructor() {
    super('sparx.science.xp.v1.ILTarget', [
      {
        no: 1,
        name: 'il_target_state_after_award',
        kind: 'message',
        T: () => ILTargetState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.ILTarget
 */
export const ILTarget = new ILTarget$Type();
// @generated message type with reflection information, may provide speed optimized methods
class XpState$Type extends MessageType<XpState> {
  constructor() {
    super('sparx.science.xp.v1.XpState', [
      {
        no: 1,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'current_xp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'level', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'last_leveled_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.XpState
 */
export const XpState = new XpState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ILTargetState$Type extends MessageType<ILTargetState> {
  constructor() {
    super('sparx.science.xp.v1.ILTargetState', [
      {
        no: 1,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assignment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'target_xp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'xp_earned', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'end_timestamp', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'cancelled_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.xp.v1.ILTargetState
 */
export const ILTargetState = new ILTargetState$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.xp.v1.Xp
 */
export const Xp = new ServiceType('sparx.science.xp.v1.Xp', [
  {
    name: 'BatchGetStudentXpState',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:teacher',
        domain: '{school_name}',
      },
    },
    I: BatchGetStudentXpStateRequest,
    O: BatchGetStudentXpStateResponse,
  },
  {
    name: 'BatchGetILTargetXp',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:teacher',
        domain: '{school_name}',
      },
    },
    I: BatchGetILTargetXpRequest,
    O: BatchGetILTargetXpResponse,
  },
  {
    name: 'GetCurrentUserILTargetXp',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'sw:student',
        domain: 'sw',
      },
    },
    I: ListCurrentUserILTargetXpRequest,
    O: ListCurrentUserILTargetXpResponse,
  },
  {
    name: 'GetCurrentUserXpState',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'sw:student',
        domain: 'sw',
      },
    },
    I: GetCurrentUserXpStateRequest,
    O: GetCurrentUserXpStateResponse,
  },
]);
